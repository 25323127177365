<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-6  col-xs-12">
                    <div class="quick-view-img">
                        <img src="{{srcImg+product.ImageSrc}}" [alt]="'Perazzi'" class="img-fluid">
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2>{{ product.Title }}</h2>
                        <h3 *ngIf="product.Price; else richiestaprezzo">{{ product.Price * currency?.Price |
                            discount:product | currency:currency?.Currency:'symbol' }}
                            <del *ngIf="product?.DiscountPrice"><span class="money">{{ product.Price * currency?.Price |
                                    currency:currency?.Currency:'symbol' }}</span></del>
                        </h3>
                        <ng-template #richiestaprezzo>
                            <a class="btn btn-sm btn-outline p-1 mb-4" title="{{ 'price on request' | translate }}"
                                [routerLink]="['/shop/', product.ShortUrl]">
                                <i class="ti-pencil-alt"></i> {{ "price on request" | translate }}
                            </a>
                        </ng-template>
                        <div class="border-product">
                            <h6 class="product-title">{{ "shoponline.Categoria" | translate }}</h6>
                            <div class="mt-1 p-0">
                                {{product.Category}}
                            </div>
                        </div>
                        <div class="product-description border-product">
                            <h6 class="product-title">{{ "shoponline.PaginaCarrello.quantita" | translate }}</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus"
                                            (click)="decrement()">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number"
                                        [value]="counter" disabled>
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" data-type="plus"
                                            (click)="increment()">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-buttons">
                            <div class="m-1" *ngIf="product.Price !== 0">
                                <a class="btn btn-solid disabled"
                                    [class.disabled]="counter > product.Stock && product.Price === 0"
                                    (click)="addToCart(product)">{{ "shoponline.DettagliProdotto.AggiungiAlCarrello" |
                                    translate }}</a>
                            </div>
                            <div class="m-1"><a (click)="visualizzaDettaglio(product.ShortUrl)" class="btn btn-solid">{{
                                    "view detail" | translate }}</a></div>
                        </div><!--product.Title.replace(' ', '-')-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>