<div class="row">
    <div class="col-md-3 service-block">
        <div class="media">
            <svg xmlns="http://www.w3.org/2000/svg" width="512px" height="512px" style="enable-background:new 0 0 512 512;" fill="currentColor" class="bi bi-calendar-event" viewBox="0 0 16 16">
                <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
              </svg>
            <div class="media-body">
                <h4>{{ "Quando" | translate }}</h4>
                <p style="color: white;">{{ "tutti i giorni" | translate }}</p>
            </div>
        </div>
    </div>
    <div class="col-md-3 service-block">
        <div class="media">
            <svg xmlns="http://www.w3.org/2000/svg" width="512px" height="512px" style="enable-background:new 0 0 512 512;" fill="currentColor" class="bi bi-hourglass-split" viewBox="0 0 16 16">
                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
              </svg>
            <div class="media-body">
                <h4>{{ "Durata" | translate }}</h4>
                <p style="color: white;">{{ "circa 1 ora" | translate }}</p> 
            </div>
        </div>
    </div>
    <div class="col-md-3 service-block">
        <div class="media">
            <svg xmlns="http://www.w3.org/2000/svg"  width="512px" height="512px" style="enable-background:new 0 0 512 512;" fill="currentColor" class="bi bi-currency-euro" viewBox="0 0 16 16">
                <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z"/>
              </svg>
            <div class="media-body">
                <h4>{{ "Prezzo" | translate }}</h4>
                <p style="color: white; line-height: 1.5;">{{ "7 euro senza degustazione" | translate }}</p>
                <p style="color: white; line-height: 1.5;">{{ "15 euro con degustazione" | translate }}</p>
                <p style="color: white; line-height: 1.5;">{{ "6 euro per i bambini" | translate }}</p>
            </div>
        </div>
    </div>
    <div class="col-md-3 service-block">
        <div class="media">
            <svg xmlns="http://www.w3.org/2000/svg" width="512px" height="512px" style="enable-background:new 0 0 512 512;" fill="currentColor" class="bi bi-translate" viewBox="0 0 16 16">
                <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/>
                <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"/>
              </svg>
            <div class="media-body">
                <h4>{{ "Lingue" | translate }}</h4>
                <p style="color: white; line-height: 1.5;">{{ "italiano" | translate }}</p>
                <p style="color: white; line-height: 1.5;">{{ "inglese" | translate }}</p> 
            </div>
        </div>
    </div>
</div>