export const environment = {
    production: false,
    instagram_token: 'INSTA_TOKEN',
    stripe_token: 'pk_test_oIhLh3BhHDHKvvWH9GCXVCI5',
    paypal_token: 'PAYPAL_TOKEN',
    paramUrlBase: "",
    srcImg:  "/media/images/",
    srcProducts:  "/media/products/",
    basePath: 'https://services.wedare.app/geodata/api/v1/',
  };
  