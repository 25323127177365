import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './dp-home/home/home.component';
import { ProductListComponent } from './dp-products/product-list/product-list.component';
import { ContactComponent } from './dp-contacts/contact/contact.component';
import { ProductDetailComponent } from './dp-products/product-detail/product-detail.component';
import { ProductCartComponent } from './dp-cart/product-cart/product-cart.component';
import { ProductCheckoutComponent } from './dp-cart/product-checkout/product-checkout.component';
import { LoginComponent } from './dp-login/login/login.component';
import { ForgetPasswordComponent } from './dp-login/forget-password/forget-password.component';
import { RegisterComponent } from './dp-login/register/register.component';
import { ProductOffersComponent } from './dp-offers/product-offers/product-offers.component';
import { ProductCheckoutSuccessComponent } from './dp-cart/product-checkout-success/product-checkout-success.component';
import { ConditionsSaleComponent } from './dp-contacts/conditions-sale/conditions-sale.component';
import { RightToWithdrawComponent } from './dp-contacts/right-to-withdraw/right-to-withdraw.component'; 
import { OrderShippingComponent } from './dp-cart/order-shipping/order-shipping.component';
import { ProductCheckoutUnsuccessfulComponent } from './dp-cart/product-checkout-unsuccessful/product-checkout-unsuccessful.component';
import { TortaDiVigoloComponent } from './torta-vigolo/torta-di-vigolo/torta-di-vigolo.component';
import { PrivacyComponent } from './dp-contacts/privacy/privacy.component';
 
const routes: Routes = [
  {
    path: '',
    component: HomeComponent, 
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  }, 
  {
    path: 'faq',
    component: ConditionsSaleComponent
  },
  {
    path: 'right-to-withdraw',
    component: RightToWithdrawComponent
  },
  {
    path: 'shop',
    component: ProductListComponent
  },
  {
    path: 'offers',
    component: ProductOffersComponent
  },
  {
    path: 'shop/:id',
    component: ProductDetailComponent
  },
  /*{
    path: 'compare',
    component: ProductCompareComponent
  },*/
  {
    path: 'contacts',
    component: ContactComponent
  },
  {
    path: 'cart',
    component: ProductCartComponent
  },
  {
    path: 'checkout',
    component: ProductCheckoutComponent
  },
  {
    path: 'checkout/success/:order_id',
    component: ProductCheckoutSuccessComponent
  },
  {
    path: 'checkout/error/:order_id',
    component: ProductCheckoutUnsuccessfulComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  }, 
  {
    path: 'detail-shipping',
    component: OrderShippingComponent
  },
  {
    path: 'torta-di-vigolo',
    component: TortaDiVigoloComponent
  }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
