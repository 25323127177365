<app-product-breadcrumb [title]="'Checkout'" [breadcrumb]="'Checkout'"></app-product-breadcrumb>

<!-- section start -->
<section class="p-0" *ngIf="false">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="error-section">
                    <h1>404</h1>
                    <h2>page not found</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->

<!-- unsuccess section start -->
<section class="section-b-space" *ngIf="orderDetails">
    <div class="container">
        <div class="row"> 
            <div class="col-md-12">
                <div class="unsuccess-text">
                    <i class="fa fa-times-circle" aria-hidden="true"></i> 
                    <h3>{{ "shoponline.PaginaCheckoutUnsuccess.Gentile" | translate }}   {{ orderDetails.order.Customer }}</h3>
                    <p class="text" >{{ "shoponline.PaginaCheckoutUnsuccess.ErrorePagamento" | translate }}. <br>
                        {{ "shoponline.PaginaCheckoutUnsuccess.VerificaBanca" | translate }}.
                    </p> 
                    <p class="text" >{{ "shoponline.PaginaCheckoutUnsuccess.AltraCartaDiCredito" | translate }}: {{ "shoponline.PaginaCheckoutUnsuccess.Riprova" | translate }}. <br>
                        {{ "shoponline.PaginaCheckoutUnsuccess.Contattaci" | translate }} <b class="gold-text">info@tortadivigolo.it</b> {{ "shoponline.PaginaCheckoutUnsuccess.Esigenza" | translate }}.
                    </p> 
                </div>
            </div>
        </div>
    </div>ù  <div class="row p-2 m-2" style="margin-bottom: 120px;">
        <div class="col-12 text-center pb-4">
            <a [routerLink]="['/']" class="btn btn-solid">{{ "home-link" | translate }}</a>
        </div>
    </div>
</section>
<!-- Section ends -->
