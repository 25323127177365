import { Component, OnInit                  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router                             } from '@angular/router';
import { CookieService                      } from 'ngx-cookie-service';
import { LoginService                       } from '../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [CookieService]
})
export class LoginComponent implements OnInit {
  hide: boolean = true;

  loginForm!: FormGroup;
  userData: {
    Email: string,
    Psw: string,
    Lang: string
  };
  userDataInvalid = false;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private cookieService: CookieService,
    private router: Router,
    private loginService: LoginService) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email]],
      Psw: ['', [Validators.required]],
    });

    if (sessionStorage.getItem("SESSIONID") !== null && 
        sessionStorage.getItem("SESSIONID") !== "" && 
        sessionStorage.getItem("SESSIONID") !== undefined) {
      this.router.navigate(['/']);
    }
  }

  get form() {
    return this.loginForm.controls;
  }

  login() {
    this.submitted = true;

    if (this.loginForm.valid) {
      const Email = this.loginForm.get('Email')?.value;
      const Psw = this.loginForm.get('Psw')?.value;

      this.userData = {
        Email: Email,
        Psw: Psw,
        Lang: localStorage.getItem("p-language")
      };

      this.loginService.postLogin(this.userData).subscribe(response => {
        sessionStorage.setItem("p-user-code", response.customer.UserCode);
        sessionStorage.setItem("SESSIONID", response.token);
        //this.cookieService.set("SESSIONID", response.token); 
        if (localStorage.getItem("p-user-code") === sessionStorage.getItem("p-user-code")) {
          this.router.navigate(["/"]).then(() => window.location.reload());
        } else {
          localStorage.setItem("p-user-code", response.customer.UserCode);
          if (response.cartItems !== null && response.cartItems !== undefined && response.cartItems !== "") {
            localStorage.setItem("cartItems", JSON.stringify(response.cartItems));
          } else {
            localStorage.setItem("cartItems", "");
          }
          this.router.navigate(["/"]).then(() => window.location.reload());
        }
      }, error => {
        this.userDataInvalid = true;
      })
    }
  }
}
