import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss']
})
export class ImageZoomComponent implements OnInit {
  @Input() imageSrc: string = 'assets/perazzi/placeholder.jpg';
  @Input() imageAlt: string = "Perazzi";
  public srcImg = environment.srcImg;
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
