import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactMail } from 'src/app/dp-contacts/contact.model';
import { ContactService } from 'src/app/dp-contacts/contact.service';
import { CartModalComponent } from 'src/app/dp-modal/cart-modal/cart-modal.component';
import { EmailConfirmComponent } from 'src/app/dp-modal/email-confirm/email-confirm.component';
import { ImageZoomComponent } from 'src/app/dp-modal/image-zoom/image-zoom.component';
import { SizeModalComponent } from 'src/app/dp-modal/size-modal/size-modal.component';
import { ProductService } from 'src/app/dp-services/product.service';
import { ProductDetailsMainSlider, ProductDetailsThumbSlider } from '../product-slider';
import { Product } from '../product.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  public withoutDate: Date; 
  public product: Product = {};
  productID: string;
  public counter: number = 1;
  public activeSlide: any = 0;
  public selectedSize: any;

  public garanzia4: number;
  public spedizioneAss: number;

  public srcImg = environment.srcImg;

  AcceptPrivacy: boolean;

  @ViewChild("sizeChart") SizeChart: SizeModalComponent;
  @ViewChild("imageModal") imageModal: ImageZoomComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;
  currency: any = this.productService.Currency;

  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;

  @ViewChild("emailSent") emailSent: EmailConfirmComponent;
  mailForm!: FormGroup;
  newMessage: ContactMail;
  submitted = false;

  constructor(private route: ActivatedRoute, private router: Router,
    private modalService: NgbModal,
    public productService: ProductService,
    private formBuilder: FormBuilder,
    private contactService: ContactService) {
  }

  ngOnInit(): void {
    //this.withoutDate = formatDate(new Date("Jan 01 0001"), 'yyyy-MM-dd','en_US');
    this.withoutDate = new Date("Jan 01 2000");
    this.AcceptPrivacy = false;
     var productid = this.route.snapshot.params.id;
     if(productid !== undefined){
       this.productID = productid;
       this.productService.getProductByID(this.productID).toPromise().then(prodotto=>{
         this.product = prodotto as Product;   
         this.garanzia4 = this.valueOfGaranzia4(this.product.Price);
         this.spedizioneAss = this.valueOfShipping(this.product.Price);
       })
     }
  
     this.mailForm = this.formBuilder.group({
      FirstName: ['', [Validators.required, Validators.maxLength(50)]],
      LastName: ['', [Validators.required, Validators.maxLength(50)]],
      Phone: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(15)]],
      Email: ['', [Validators.required, Validators.email]],
      Text: ['', [Validators.required, Validators.maxLength(400)]],
      EmptyField: ['', [Validators.nullValidator]]
    });
  }

  get form() {
    return this.mailForm.controls;
  }

  valueOfGaranzia4(x: number){
     var g = (x / 100) * 3.5;
     return Number(g.toFixed(2));
  }

  valueOfShipping(x: number){
    var g = (x / 100) * 1.2 + 10.5;
    return Number(g.toFixed(2));
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Get Product Size
  Size(variants) {
    const uniqSize = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqSize.indexOf(variants[i].size) === -1 && variants[i].size) {
        uniqSize.push(variants[i].size)
      }
    }
    return uniqSize
  }

  selectSize(size) {
    this.selectedSize = size;
  }

  // Increament
  increment() {
    this.counter++;
  }

  // Decrement
  decrement() {
    if (this.counter > 1) this.counter--;
  }

  // Buy Now
  async buyNow(product: any) {
    product.Quantity = this.counter || 1;
    const status = await this.productService.addToCart(product);
    if (status)
      this.router.navigate(['/checkout']);
  }

  openZoomImage() {
    const modalRef = this.modalService.open(ImageZoomComponent, {
      size: 'lg',
      centered: true
    });
    modalRef.componentInstance.imageSrc = this.product.ImageSrc;
    modalRef.componentInstance.imageAlt = "Perazzi";
  }

  sendMail() {
    this.submitted = true;
    if (this.mailForm.valid) {
      const FirstName = this.mailForm.get('FirstName')?.value;
      const LastName = this.mailForm.get('LastName')?.value;
      const Phone = this.mailForm.get('Phone')?.value;
      const Email = this.mailForm.get('Email')?.value;
      const Text = this.mailForm.get('Text')?.value;
      const EmptyField = this.mailForm.get('EmptyField')?.value;

      if (EmptyField === "" || EmptyField === null || EmptyField === undefined) {
        this.newMessage = {
          FirstName: FirstName,
          LastName: LastName,
          Phone: Phone.toString(),
          Email: Email,
          Text: Text,
          EmptyField: "",
          ProductID: this.productID
        }
        
        this.contactService.postMail(this.newMessage).subscribe(response => {
          this.submitted = false;
          this.mailForm.reset();
          this.emailSent.openModal();
        });
      } else {
        this.router.navigate(['/shop/' + this.productID]);
      }
    }
  }

  openNewTab() {
    window.open('/privacy', '_blank');
  }

  disableButton(){
    this.AcceptPrivacy = !this.AcceptPrivacy;
  }
}
