<section class="p-0 home section-home">
    <div class="container"><!--position-relative overflow-hidden text-center -->
        <div class="row" style="display: grid;">
            <div class="col-md-10 p-lg-3 mx-auto"><!--p-lg-5 mx-auto -->
                <div class="row" style="padding-top: 120px;">
                    <div class="col-md-6 my-auto p-4 text-center border-home">
                        <div class="slider-contain" style="justify-content: right;">
                            <div>
                                <h1 class="title-brown" style="text-shadow: 0px -1px 0px #fff, 2px 2px 0px #cc9551;color: #4e2724;">La
                                    Torta di Vigolo</h1>
                                <h4 style="color: #212529;">Tramandiamo un classico e tante altre prelibatezze</h4>
                                <a [routerLink]="['/shop']" class="btn btn-solid">
                                    Scopri tutto
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-device box-shadow d-none d-md-block"></div>
            <div class="product-device product-device-2 box-shadow d-none d-md-block"></div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title-brown text-center">Noi
                    <span style="font-size: 32px;"><i class="fa fa-heart wow pulse animated"
                            data-wow-iteration="infinite"
                            style="visibility: visible; animation-iteration-count: infinite; animation-name: pulse;"></i></span>
                    il cioccolato
                </h2>
            </div>
            <div class="col-md-8 p-lg-3 mx-auto">
                <div>
                    <p class="text">
                        La Torta di Vigolo non è solo una ricetta. <br>
                        La Torta di Vigolo è la miglior torta al cioccolato che possiate assaggiare, grazie ad anni
                        spesi nella ricerca delle migliori materie prime e nella rispetto della nostra ricetta. <br>
                        Ogni Torta di Vigolo è fatta a mano con passione, rispettando i tempi della crema e del forno,
                        ognuna con la sua storia e la sua sfumatura di sapori.<br>
                        Non chiedeteci un prodotto sempre uguale nel peso e nella miscela di sapori. Noi del forno
                        Perazzi non siamo macchine, siamo persone che mettono nelle torte la stessa passione che mette
                        una nonna che cucina per i propri nipoti.

                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<!--Cosa facciamo-->
<section class="banner-padding banner-furniture">
    <div class="container">
        <div class="row partition2 p-2">
            <div class="col-md-12">
                <h2 class="title-brown text-center">Cosa facciamo</h2>
            </div>
            <div class="col-md-6" *ngFor="let collection of collections">
                <div class="collection-banner p-left text-left p-2">
                    <img [src]="collection.image" class="img-fluid" alt="collection-banner">
                    <div class="contain-banner">
                        <div style="background: rgba(255, 255, 255, 0.7);">
                            <h2>{{collection.title}}</h2>
                            <h4 class="pt-2">{{collection.save}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--A Chi la portiamo-->
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title-brown text-center">A Chi la portiamo</h2>
            </div>
            <div class="col-md-12 py-4">
                <div class="pagitation-owl">
                    <owl-carousel-o [options]="logoSliderConfig" class="no-arrow">
                        <ng-container *ngFor="let logo of logos">
                            <ng-template carouselSlide>
                                <div class="media">
                                    <a>
                                        <img [src]="logo.image" class="img-fluid lazy-loading" alt="image">
                                    </a>
                                    <div class="media-body align-self-center m-2">
                                        <h4 style="font-weight: 600;">{{logo.name}}</h4>
                                        <a>
                                            <h6>{{logo.city}}</h6>
                                            <h6><i>"{{logo.subtitle}}"</i></h6>
                                        </a>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</section>
<!--   -->

<!--Volete le nostre torte nei vostri punti vendita?-->
<section class="banner-padding banner-furniture">
    <div class="container">
        <div class="row partition2 p-2">
            <div class="col-md-12">
                <h2 class="title-brown text-center">Volete le nostre torte nei vostri punti vendita?</h2>
            </div>
            <div class="col-md-12 text-center py-4">
                <h4>Se anche voi volete offrire la Torta di Vigolo e le altre specialità ai vostri clienti.
                </h4>
                <a [routerLink]="['/contacts']" class="btn btn-solid">
                    Contattateci
                </a>
            </div>
        </div>
    </div>
</section>

<section class="section-orario full-banner parallax-banner1 parallax p-left p-2">
    <div class="container">
        <div class="row mx-2">
            <div class="col-md-12">
                <div class="section-title-orario">
                    <h5>{{'home.orari.Orari-apertura' | translate}}</h5>
                </div>
            </div>
            <div class="col-md-12 pt-3 border-orari">
                <div class="row">
                    <div class="col-4 text-orario">
                        {{'home.orari.lunedi-sabato' | translate}}:
                    </div>
                    <div class="col-8 text-end text-orario">
                        dalle 07:00 alle 13:00 e
                        dalle 16:00 alle 19.30
                    </div>

                    <div class="col-4 text-orario">
                        {{'home.orari.domenica' | translate}}:
                    </div>
                    <div class="col-8 text-end text-orario">
                        dalle 08:00 alle 12:00
                    </div>

                    <div class="col-4 text-orario">
                        {{'home.orari.giovedi' | translate}}:
                    </div>
                    <div class="col-8 text-end text-orario">
                        Chiuso
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-tap-to-top></app-tap-to-top>