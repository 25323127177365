<app-product-breadcrumb [title]="'privacy'" [breadcrumb]="'privacy'"></app-product-breadcrumb>

<section class="contact-page section-b-space" style="padding-bottom: 100px;">
    <div class="container">
        <div style="padding-top: 40px;"></div>
        <div class="row section-b-space">

            <div class="collection-content col">
                <div class="page-main-content">
                    <div class="row">
                        <div class="col-sm-12">
                            <h4 class="title-yellow-2 text-center pb-1">{{policy.Privacy.title}}</h4>
                            <div> 
                                <p class="text" [innerHTML]="policy.Privacy.text"> </p> 
                            </div> 
                        </div>
                        <div class="col-sm-12">
                            <h4 class="gold-text m-0">{{policy.Info1.title}}</h4>
                            <div> 
                                <p class="text" [innerHTML]="policy.Info1.text"> </p>  
                            </div> 
                        </div>
                        <div class="col-sm-12">
                            <h4 class="gold-text m-0">{{policy.Info2.title}}</h4> 
                            <div> 
                                <p class="text" [innerHTML]="policy.Info2.text"> </p> 
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <h4 class="gold-text m-0">{{policy.Info3.title}}</h4> 
                            <div> 
                                <p class="text" [innerHTML]="policy.Info3.text"> </p> 
                            </div>
                        </div> 
                        <div class="col-sm-12">
                            <h4 class="gold-text m-0">{{policy.Info4.title}}</h4> 
                            <div> 
                                <p class="text" [innerHTML]="policy.Info4.text"> </p> 
                            </div>
                        </div> 
                        <div class="col-sm-12">
                            <h4 class="gold-text  m-0">{{policy.Info5.title}}</h4> 
                            <div> 
                                <p class="text" [innerHTML]="policy.Info5.text"> </p> 
                            </div>
                        </div> 
                        <div class="col-sm-12">
                            <h4 class="gold-text m-0">{{policy.Info6.title}}</h4> 
                            <div> 
                                <p class="text" [innerHTML]="policy.Info6.text"> </p> 
                            </div>
                        </div> 
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <h4 class="title-yellow-2 text-center pb-1">{{policy.Privacy2.title}}</h4> 
                        </div>
                        <div class="col-sm-12">
                            <h4 class="gold-text m-0">{{policy.Info7.title}}</h4>
                            <div> 
                                <p class="text" [innerHTML]="policy.Info7.text"> </p>  
                            </div> 
                        </div>
                        <div class="col-sm-12">
                            <h2>INFORMAZIONI GENERALI</h2>
                        </div>
                        <div class="col-sm-12">
                            <h4 class="gold-text m-0">{{policy.Info8.title}}</h4> 
                            <div> 
                                <p class="text" [innerHTML]="policy.Info8.text"> </p> 
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <h2>INFORMAZIONI SPECIFICHE</h2>
                            <h5 class="gold-text">INFORMAZIONI SPECIFICHE, RELATIVE AL TRATTAMENTO DEI DATI IN DIVERSI AMBITI</h5> 
                        </div>
                        <div class="col-sm-12">
                            <h4 class="gold-text m-0">{{policy.Info9.title}}</h4> 
                            <div> 
                                <p class="text" [innerHTML]="policy.Info9.text"> </p> 
                            </div>
                        </div> 
                        <div class="col-sm-12">
                            <h4 class="gold-text m-0">{{policy.Info10.title}}</h4> 
                            <div> 
                                <p class="text" [innerHTML]="policy.Info10.text"> </p> 
                            </div>
                        </div> 
                        <div class="col-sm-12">
                            <h4 class="gold-text m-0">{{policy.Info11.title}}</h4> 
                            <div> 
                                <p class="text" [innerHTML]="policy.Info11.text"> </p> 
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>

<app-tap-to-top></app-tap-to-top>