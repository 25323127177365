<app-product-breadcrumb [title]="'shoponline.PaginaCheckout.Checkout'"
    [breadcrumb]="'shoponline.PaginaCheckout.Checkout'"></app-product-breadcrumb>
<section class="section-b-space">
    <div class="container padding-cls">
        <div class="checkout-page">
            <div class="checkout-form">
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-sm-12 col-xs-12" style="margin-bottom: 50px;">
                            <div class="checkout-title">
                                <h3>{{ "shoponline.PaginaCheckout.DatiFatturazione" | translate }}</h3>
                                <p>{{ "shoponline.PaginaCheckout.MsgFatturazione" | translate }}</p>
                            </div>


                            <!--test payment-->
                            <div class="row check-out">
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">{{ "shoponline.PaginaCheckout.form.Nome" | translate }}*
                                    </div>
                                    <input type="text" name="firstname"
                                        [formControl]="checkoutForm.controls['firstname']" value="" placeholder=""
                                        autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.firstname.touched && checkoutForm.controls.firstname.errors?.required"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Obbligatorio" | translate }}.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.firstname.touched && checkoutForm.controls.firstname.errors?.pattern"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Alfabetico" | translate }}.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.firstname.touched && checkoutForm.controls.firstname.errors?.maxlength"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Max" | translate }} 50 {{
                                        "shoponline.PaginaCheckout.form.Caratteri" | translate }}.
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">{{ "shoponline.PaginaCheckout.form.Cognome" | translate }}*
                                    </div>
                                    <input type="text" name="lastname" [formControl]="checkoutForm.controls['lastname']"
                                        value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.lastname.touched && checkoutForm.controls.lastname.errors?.required"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Obbligatorio" | translate }}.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.lastname.touched && checkoutForm.controls.lastname.errors?.pattern"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Alfabetico" | translate }}.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.lastname.touched && checkoutForm.controls.lastname.errors?.maxlength"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Max" | translate }} 50 {{
                                        "shoponline.PaginaCheckout.form.Caratteri" | translate }}.
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label"> {{ "shoponline.PaginaCheckout.form.Telefono" | translate
                                        }}*</div>
                                    <input type="text" name="phone" value=""
                                        [formControl]="checkoutForm.controls['phone']" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.phone.touched && checkoutForm.controls.phone.errors?.required"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Obbligatorio" | translate }}.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.phone.touched && checkoutForm.controls.phone.errors?.pattern"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Numeri" | translate }}.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.phone.touched && checkoutForm.controls.phone.errors?.maxlength"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Max" | translate }} 30 {{
                                        "shoponline.PaginaCheckout.form.Caratteri" | translate }}.
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">{{ "shoponline.PaginaCheckout.form.Email" | translate }}*
                                    </div>
                                    <input type="text" name="email" [formControl]="checkoutForm.controls['email']"
                                        (change)="changeEmail()" value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.required"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Obbligatorio" | translate }}.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.email"
                                        class="text text-danger">
                                        {{ "shoponline.PaginaCheckout.form.EmailNonValida" | translate }}
                                    </div>
                                    <div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.maxlength"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Max" | translate }} 50 {{
                                        "shoponline.PaginaCheckout.form.Caratteri" | translate }}.
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">{{ "shoponline.PaginaCheckout.form.Paese" | translate }}*
                                    </div>
                                    <select name="country" [formControl]="checkoutForm.controls['country']"
                                        (change)="calcolaSpedizione()" class="mb-0">
                                        <option value="" selected>{{ "shoponline.PaginaCheckout.form.SelezionaPaese" |
                                            translate }}</option>
                                        <option *ngFor="let country of countries" [value]="country.name">
                                            {{country.name}}</option>
                                    </select>
                                    <div *ngIf="checkoutForm.controls.country.touched && checkoutForm.controls.country.errors?.required"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Obbligatorio" | translate }}.
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12"
                                    *ngIf="checkoutForm.get('country')?.value === 'Italia'">
                                    <div class="field-label">{{ "shoponline.PaginaCheckout.form.Provincia" | translate
                                        }}*</div>
                                    <select name="state" [formControl]="checkoutForm.controls['state']"
                                        (change)="calcolaSpedizione()" class="mb-0">
                                        <option value="" selected>{{ "shoponline.PaginaCheckout.form.SelezionaProvincia"
                                            |
                                            translate }}</option>
                                        <option *ngFor="let provincia of province" [ngValue]=provincia.denomIta>
                                            {{provincia.denomIta}}</option>
                                    </select>

                                    <div *ngIf="checkoutForm.controls.state.touched && checkoutForm.controls.state.errors?.required"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Obbligatorio" | translate }}.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.state.touched && checkoutForm.controls.state.errors?.maxlength"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Max" | translate }} 30 {{
                                        "shoponline.PaginaCheckout.form.Caratteri" | translate }}.
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">{{ "shoponline.PaginaCheckout.form.Citta" | translate }}*
                                    </div>
                                    <input type="text" name="town" [formControl]="checkoutForm.controls['town']"
                                        value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.town.touched && checkoutForm.controls.town.errors?.required"
                                        class="text text-danger">
                                        {{ "shoponline.PaginaCheckout.form.Obbligatorio" | translate }}.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.town.touched && checkoutForm.controls.town.errors?.maxlength"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Max" | translate }} 30 {{
                                        "shoponline.PaginaCheckout.form.Caratteri" | translate }}.
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">{{ "shoponline.PaginaCheckout.form.CAP" | translate }}*
                                    </div>
                                    <input type="text" name="postalcode"
                                        [formControl]="checkoutForm.controls['postalcode']" value="" placeholder=""
                                        autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.postalcode.touched && checkoutForm.controls.postalcode.errors?.required"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Obbligatorio" | translate }}
                                    </div>
                                    <div *ngIf="checkoutForm.controls.postalcode.touched && checkoutForm.controls.postalcode.errors?.maxlength"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Max" | translate }} 10 {{
                                        "shoponline.PaginaCheckout.form.Caratteri" | translate }}.
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">{{ "shoponline.PaginaCheckout.form.Indirizzo" | translate
                                        }}*</div>
                                    <input type="text" name="address" [formControl]="checkoutForm.controls['address']"
                                        value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.address.touched && checkoutForm.controls.address.errors?.required"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Obbligatorio" | translate }}.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.address.touched && checkoutForm.controls.address.errors?.maxlength"
                                        class="text text-danger m-0">
                                        {{ "shoponline.PaginaCheckout.form.Max" | translate }} 70 {{
                                        "shoponline.PaginaCheckout.form.Caratteri" | translate }}.
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12 mb-0">
                                    <input type="checkbox" name="richiestaFattura" [(ngModel)]="richiestaFattura"
                                        id="richiestaFattura" (change)="disablePlaceOrder()">
                                    <label class="field-label pl-2" for="richiestaFattura">{{
                                        "shoponline.PaginaCheckout.Fattura" | translate
                                        }}</label>
                                </div>
                                <ng-container *ngIf="richiestaFattura">
                                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div class="m-0">
                                            {{ "shoponline.PaginaCheckout.form.ObbligatorioCompilare" | translate }}
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div class="field-label">{{ "shoponline.PaginaCheckout.form.Paese" | translate
                                            }}*</div>
                                        <select class="pb-0 mb-0" name="country"
                                            [formControl]="checkoutForm.controls['vatcountry']">
                                            <option value="" selected>{{ "shoponline.PaginaCheckout.form.SelezionaPaese"
                                                | translate }}</option>
                                            <option *ngFor="let country of countries" [value]="country.code">
                                                {{country.name}}</option>
                                        </select>
                                        <div *ngIf="(checkoutForm.controls.vatcountry.touched && checkoutForm.controls.vatcountry.errors?.required) || invalidIVA"
                                            class="text text-danger m-0">
                                            {{ "shoponline.PaginaCheckout.form.Obbligatorio" | translate }}.
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div class="field-label">{{ "shoponline.PaginaCheckout.form.PartitaIVA" |
                                            translate }}* </div>
                                        <input type="text" name="vat" [formControl]="checkoutForm.controls['vat']"
                                            placeholder="" autocomplete="off">
                                        <div *ngIf="(checkoutForm.controls.vat.touched && checkoutForm.controls.vat.errors?.required) || invalidIVA"
                                            class="text text-danger m-0">
                                            {{ "shoponline.PaginaCheckout.form.Obbligatorio" | translate }}.
                                        </div>
                                        <div *ngIf="(checkoutForm.controls.vat.touched && checkoutForm.controls.vat.errors?.required) || (checkoutForm.controls.vat.touched && fatturaValida===false)"
                                            class="text text-danger m-0">
                                            {{ "shoponline.PaginaCheckout.form.PIVAnonvalida" | translate }}.
                                        </div>
                                        <div *ngIf="checkoutForm.controls.vat.touched && checkoutForm.controls.vat.errors?.maxlength"
                                            class="text text-danger m-0">
                                            {{ "shoponline.PaginaCheckout.form.Max" | translate }} 30 {{
                                            "shoponline.PaginaCheckout.form.Caratteri" | translate }}.
                                        </div>
                                        <div *ngIf="checkoutForm.controls.vat.touched && checkoutForm.controls.vat.errors?.pattern"
                                            class="text text-danger m-0">
                                            {{ "shoponline.PaginaCheckout.form.Numeri" | translate }}.
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div class="field-label">{{ "shoponline.PaginaCheckout.form.CodiceSDI" |
                                            translate
                                            }}*</div>
                                        <input type="text" name="sdicode"
                                            [formControl]="checkoutForm.controls['sdicode']" value="" placeholder=""
                                            autocomplete="off">
                                        <div *ngIf="checkoutForm.controls.sdicode.touched && checkoutForm.controls.sdicode.errors?.required"
                                            class="text text-danger m-0">
                                            {{ "shoponline.PaginaCheckout.form.Obbligatorio" | translate }}.
                                        </div>
                                        <div *ngIf="checkoutForm.controls.sdicode.touched && checkoutForm.controls.sdicode.errors?.maxlength"
                                            class="text text-danger m-0">
                                            {{ "shoponline.PaginaCheckout.form.Max" | translate }} 30 {{
                                            "shoponline.PaginaCheckout.form.Caratteri" | translate }}.
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div class="field-label">{{ "shoponline.PaginaCheckout.form.Azienda" | translate
                                            }}*</div>
                                        <input type="text" name="companyname"
                                            [formControl]="checkoutForm.controls['companyname']" value=""
                                            autocomplete="off">
                                    </div> 
                                </ng-container>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 col-xs-12">
                            <div class="checkout-details">
                                <div class="order-box">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="px-1" scope="col">{{ "shoponline.PaginaCarrello.Prodotto" |
                                                    translate }}</th>
                                                <th class="px-1" scope="col">{{ "shoponline.PaginaCarrello.prezzo" |
                                                    translate }}</th>
                                                <th class="px-1" scope="col">{{ "shoponline.PaginaCarrello.quantita" |
                                                    translate }}</th>
                                                <th class="px-1" scope="col">{{ "total" |
                                                    translate }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let product of products">
                                                <td class="px-1">{{ product.Title }}</td>
                                                <td class="px-1 text-center">{{ (product.Price | discount:product) *
                                                    productService?.Currency.Price |
                                                    currency:productService?.Currency.Currency:'symbol' }}</td>
                                                <td class="px-1 text-center">
                                                    {{ product.Quantity }}
                                                </td>
                                                <td class="px-1 text-center">
                                                    {{ ((product.Price | discount:product) * product.Quantity) *
                                                    productService?.Currency.Price |
                                                    currency:productService?.Currency.Currency:'symbol'
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <ul class="qty" *ngIf='!products.length'>
                                        <li class="empty-checkout"> {{ "shoponline.PaginaCheckout.CarrelloVuoto" |
                                            translate }}
                                        </li>
                                    </ul>
                                    <ul class="sub-total">
                                        <!--<li>
                                            {{ "subtotal" | translate }} <span class="count">{{ getTotal | async |
                                                currency:productService?.Currency.Currency:'symbol'}}</span>
                                        </li>-->
                                        <li *ngIf='products.length' class="px-1">
                                            <div class="row">
                                                <div class="col-md-8">
                                                    {{ "shoponline.PaginaCheckout.spedizione" | translate }}
                                                    <p style="font-weight: 300;">{{
                                                        "shoponline.PaginaCarrello.spedizione-messaggio" | translate }}
                                                    </p>
                                                </div>
                                                <div class="col-md-4"
                                                    *ngIf="spedizioneScelta !== undefined && checkoutForm.get('country')?.value!==undefined && checkoutForm.get('country')?.value!==''">
                                                    € {{spedizioneScelta}}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="total">
                                        <li>{{ "total" | translate }} <span class="count">{{ getTotal | async |
                                                currency:productService?.Currency.Currency:'symbol'}}</span></li>
                                    </ul>
                                </div>

                                <div class="payment-box" *ngIf='products.length'>
                                    <div class="upper-box">
                                        <div class="payment-options">
                                            <ul>
                                                <li>
                                                    <div class="radio-option">
                                                        <input type="radio" [(ngModel)]="payment" [value]="'Stripe'"
                                                            name="payment-group" id="payment-1">
                                                        <label for="payment-1"> {{
                                                            "shoponline.PaginaCheckout.creditCard" | translate }}
                                                            (Stripe)
                                                        </label>
                                                    </div>
                                                </li>
                                                <li *ngIf="checkoutForm.get('email').value !== ''">
                                                    <div class="radio-option form-group">
                                                        <label for="coupon-1"> {{
                                                            "shoponline.PaginaCheckout.form.Coupon" | translate }}
                                                        </label>
                                                        <div class="input-group ml-3">
                                                            <input type="text" class="form-control ngp-field"
                                                                [formControl]="checkoutForm.controls['couponcode']"
                                                                name="payment-group" id="coupon-1">
                                                            <div *ngIf="errorText !== ''"
                                                                class="input-group-append mt-2">
                                                                <ngb-alert type="danger" (close)="alertClose()"
                                                                    class="alert alert-border-left alert-dismissible fade show custom-alert">
                                                                    <i class="fa fa-ban"></i> &nbsp;
                                                                    <strong> {{errorText}}!</strong>
                                                                </ngb-alert>
                                                            </div>
                                                            <div *ngIf="couponAmount!==0">
                                                                Sconto coupon: {{couponAmount}} €
                                                            </div>
                                                            <div class="input-group-append mt-2">
                                                                <button type="submit" class="btn-solid btn btn-sm"
                                                                    (click)="verificaCoupon()">
                                                                    {{ "verifica" | translate }} coupon
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="text-right">
                                        <button type="submit" class="btn-solid btn" [disabled]="disablePlaceOrder()"
                                            (click)="checkout('Stripe')">
                                            {{ "place order" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>