/**
 * GeoData API
 * Server RestAPI per la gestione di geodata.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DTOComuneReadDTO { 
    capoluogo?: boolean;
    codAlfaIstatComune?: string;
    codCatastale?: string;
    codComune103?: number;
    codComune107?: number;
    codComune110?: number;
    codNUTS12010?: string;
    codNUTS12021?: string;
    codNUTS22010?: string;
    codNUTS22021?: string;
    codNUTS32010?: string;
    codNUTS32021?: string;
    codNumIstatComune?: number;
    codProvincia?: string;
    codRegione?: string;
    codRipartizioneGeografica?: number;
    codSovracomunale?: string;
    codicePostale?: string;
    denomEst?: string;
    denomIta?: string;
    denomItaEst?: string;
    denomRegione?: string;
    denomSovracomunale?: string;
    latNord?: number;
    latSud?: number;
    latitudine?: number;
    lngNord?: number;
    lngSud?: number;
    longitudine?: number;
    placeId?: string;
    progressivoComune?: string;
    ripartizioneGeografica?: string;
    siglaAutomobile?: string;
    tipoSovracomunale?: string;
}
