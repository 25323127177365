<!--footer section -->
<footer [ngClass]="class">
	<div class="light-layout">
		<div class="container">
			<section class="section-b-space border-b">
				<div class="row footer-theme2">
					<div class="col-xl-2 col-4 mt-2 pt-2">
						<div class="footer-contant">
							<div class="text-end">
								<img src="assets/perazzi/Marchio_Botteghe_storiche_castellarquato.jpg"
									style="max-height: 180px;" alt="Marchio Botteghe storiche castell'arquato">
							</div>
						</div>
					</div>
					<div class="col-xl-4 col-8 mt-2 pt-2">
						<div class="footer-contant">
							<div class="footer-logo" id="footerlogo">
								<img src="/assets/perazzi/logo-perazzi.png" alt="perazzi" style="max-height: 180px;">
							</div>
							<div class="footer-social social-white">
								<ul>
									<li>
										<a (click)="goFacebook()">
											<i class="fa fa-facebook-f"></i>
										</a>
									</li>
									<li>
										<a (click)="goInstagram()">
											<i class="fa fa-instagram"></i>
										</a>
									</li>
									<li>
										<a (click)="goTwitter()">
											<i class="fa fa-twitter"></i>
										</a>
									</li>
									<li>
										<a (click)="goTripadvisor()">
											<i class="fa fa-tripadvisor"></i>
										</a>
									</li>
								</ul>
							</div>
						</div>

					</div>
					<div class="col-xl-6 col-12 mt-2 pt-2">
						<div class="footer-contant">
							<ul class="contact-details">
								<li><i class="fa fa-map-marker"></i>&nbsp; Via Alberoni, 24 - 29014 - Vigolo Marchese
									(PC)</li>
								<li><i class="fa fa-phone"></i>&nbsp; <a href="tel:+390523896128"> +39 0523 896128</a>
								</li>
								<li><i class="fa fa-envelope-o"></i>&nbsp; <a [routerLink]="['/contacts']">
										info@tortadivigolo.it</a></li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>

	<div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 col-md-12 col-sm-12 d-flex justify-content-center">
					<div class="footer-end">
						<p class="py-1"><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}}
							Pasticceria Perazzi s.r.l. - Partita I.V.A.: 01062700339.
							{{ "Tutti i diritti riservati. Vedi la nostra" | translate }} <a [routerLink]="['/privacy']"
								style="color: #d8c636;">{{ "Privacy Policy" | translate }}.</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<!--footer section end -->