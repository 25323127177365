<div class="img-wrapper">
  <div class="lable-block">
    <!--<span class="lable4" *ngIf="product.New">{{ "new" | translate }}</span>-->
    <span class="lable3" *ngIf="product.Sale">{{ "sale" | translate }}</span>
  </div>
  <div class="front">
    <a (click)="changePage(product.ShortUrl)">
      <!--product.Title.replace(' ', '-')-->
      <div class="product-image d-flex">
        <img [defaultImage]="ImageSrc ? ImageSrc : '/assets/perazzi/placeholder.jpg'"
          [lazyLoad]="ImageSrc ? ImageSrc : srcImg + product.ImageSrc" src="{{srcImg+product.ImageSrc}}"
          class="img-fluid lazy-loading" alt="Perazzi">
      </div>
    </a>
  </div>

  <div class="cart-detail">

  </div>
</div>
<div class="product-info">
  <div style="padding-top: 0px;">
    <!--<bar-rating [rate]="5" [readOnly]="true"></bar-rating>-->
    <a (click)="changePage(product.ShortUrl)">
      <!--product.Title.replace(' ', '-') | titlecase-->
      <div class="text m-0" [routerLink]="['/shop/', product.ShortUrl]">
        <span class="gold-text">
          {{ product?.Title }}
        </span> 
      </div> 
    </a>
    <h4 *ngIf="product?.Price !== 0">
      {{ product?.Price * currency?.Price | discount:product | currency:currency?.Currency:'symbol' }}
      <del *ngIf="product?.DiscountPrice"><span class="money"> {{ product?.Price * currency?.Price |
          currency:currency?.Currency:'symbol' }}</span></del>
    </h4>
    <ul>
      <!--*ngIf="Color(product?.variants).length"-->
      <li *ngIf="product.Subcategory">
        {{product?.Subcategory}}
      </li>
    </ul>
    <div class="add-btn" *ngIf="product.Price!==0 ; else richiestaprezzo">
      <a class="btn btn-outline btn-secondary"
        title="{{ 'shoponline.DettagliProdotto.AggiungiAlCarrello' | translate }}" (click)="QuickView.openModal()"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i> {{ "shoponline.DettagliProdotto.AggiungiAlCarrello" | translate }}
      </a>
      <a class="btn btn-outline btn-secondary"
        title="{{ 'shoponline.DettagliProdotto.AggiungiAlCarrello' | translate }}" (click)="addToCart(product)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i> {{ "shoponline.DettagliProdotto.AggiungiAlCarrello" | translate }}
      </a>
    </div>
    <ng-template #richiestaprezzo>
      <a class="p-1" style="color: #6d7e87;" title="{{ 'price on request' | translate }}"
        [routerLink]="['/shop/', product.ShortUrl]">
        <i class="ti-pencil-alt"></i> {{ "price on request" | translate }}
      </a>
    </ng-template>
  </div>
</div>


<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>