import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface Slide {
  title: string;
  subTitle: string;
  image: string;
  link?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SliderService {

  constructor(private http: HttpClient) { }
 
}