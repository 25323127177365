import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from './dp-services/order.service';
import { ProductService } from './dp-services/product.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  moon: boolean = true;
  todayDate = new Date();

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(
    private meta: Meta,
    private title: Title,
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService,
    private orderService: OrderService,
    private prService: ProductService) {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('p-language') === undefined || localStorage.getItem('p-language') === null || localStorage.getItem('p-language') === "") {
       if (navigator.language === "it-IT" || navigator.language === "it-CH" || navigator.language === "it") {
          localStorage.setItem('p-language', 'it');
          translate.setDefaultLang('it');
        } else {
          localStorage.setItem('p-language', 'en');
          translate.setDefaultLang('en');
        }
      } else {
        translate.setDefaultLang(localStorage.getItem('p-language'));
      }

      translate.addLangs(['en', 'it']);
    }

    if (localStorage.getItem('p-user-code') === undefined || localStorage.getItem('p-user-code') === null || localStorage.getItem('p-user-code') === "") {
      orderService.createUserCode().subscribe(responseData => {
        localStorage.setItem("cartItems", "");
        localStorage.setItem('p-user-code', responseData);
      })
    }

    prService.getProducts.subscribe(data => {
      if (data !== undefined) {  
        localStorage['products'] = JSON.stringify(data);
        let carrello = prService.gethttpProductsInCart().pipe(map(data => data));
        carrello.subscribe(next => {
          if (next !== "null" && next !== null && next !== undefined) {
            localStorage['cartItems'] = JSON.stringify(next);
          }
        });
      }
    })

    if (localStorage.getItem("layoutMode") === "dark") {
      this.moon = false;
      document.body.classList.toggle('dark')
    }


    this.title.setTitle("La Torta di Vigolo | Home");
    this.meta.addTags([
      {
        name: "description",
        content: "La Torta di Vigolo non è solo una ricetta. La Torta di Vigolo è la miglior torta al cioccolato che possiate assaggiare, grazie ad anni spesi nella ricerca delle migliori materie prime e nella rispetto della nostra ricetta."
      },
      { name: "keywords", content: "La Torta di Vigolo, Pasticceria Perazzi, Perazzi, Piacenza" },
      { name: "robots", content: "index, follow" },
      { name: "og:title", content: "La Torta di Vigolo || Pasticceria Perazzi" },
      { name: "og:type", content: "sito web" },
      { name: "og:url", content: "https://www.tortadivigolo.it" }, 
      { name: "og:site_name", content: "La Torta di Vigolo || Pasticceria Perazzi" },
      {
        name: "og:description",
        content: "Il forno Perazzi || Solo anni di esperienza lavorativa, ed una attenta scelta delle materie prime ci ha permesso di farci conoscere in tutta la provincia di Piacenza e uscire dai nostri confini provinciali..."
      }
    ]);
  }

  customizeLayoutDark() {
    this.moon = !this.moon;
    if (this.moon === false) {
      localStorage.setItem('layoutMode', "dark");
    } else {
      localStorage.setItem('layoutMode', "");
    }
    document.body.classList.toggle('dark')
  } 
}
