<app-product-breadcrumb [title]="'Forget Password'" [breadcrumb]="'Forget Password'"></app-product-breadcrumb>
<!-- section start -->
<section class="pwd-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <h3>{{ "forget your password" | translate }}</h3>
                <p class="text-center">{{ "Enter your email and we'll send you a link to reset your password" | translate }}.</p>
                <form class="theme-form" (ngSubmit)="sendEmail()" [formGroup]="resetPassword">
                    <div class="form-row">
                        <div class="col-md-12">
                            <input type="email" class="form-control" id="email" 
                            formControlName="Email"
                            placeholder="{{ 'Email' | translate }}"
                            [ngClass]="{'is-invalid': submitted && form.Email.errors}" />
                            <div *ngIf="submitted && form.Email.errors" class="invalid-feedback p-0">
                                <span *ngIf="form.Email.errors.required">{{ "Required field" | translate }}</span>
                                <span *ngIf="form.Email.errors.email">{{ "Email invalid" | translate }}</span>
                            </div>
                        </div>
                        <div class="col-md-12 text-center">
                            <span class="mx-1">
                             <button class="btn btn-solid" type="submit">{{ "submit" | translate }}</button>
                            </span>
                            <span class="mx-1">
                             <button class="btn btn-solid" [routerLink]="['/login']" style="background: #777777;">{{ "back" | translate }}</button>
                            </span>
                         </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->