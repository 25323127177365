<app-product-breadcrumb [title]="'shoponline.PaginaCarrello.Carrello'"
    [breadcrumb]="'shoponline.PaginaCarrello.Carrello'"></app-product-breadcrumb>
<!-- section start -->
<section class="cart-section section-b-space">
    <div class="container">
        <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!products.length" style="margin-bottom: 100px;">
            <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
            <h3><strong>{{ "shoponline.PaginaCarrello.CarrelloVuoto" | translate }}</strong></h3>
            <!--<h4>Add something to make me happy :)</h4>-->
        </div>
        <div class="row" *ngIf="products.length">
            <div class="col-sm-12">
                <table class="table cart-table table-responsive-xs">
                    <thead>
                        <tr class="table-head">
                            <th scope="col" style="width: 15%;">{{ "shoponline.PaginaCarrello.immagine" | translate }}
                            </th>
                            <th scope="col" style="width: 30%;">{{ "shoponline.PaginaCarrello.prodotto" | translate }}
                            </th> 
                            <th scope="col" style="width: 12%;">{{ "shoponline.PaginaCarrello.prezzo" | translate }}
                            </th>
                            <th scope="col" style="width: 10%;">{{ "shoponline.PaginaCarrello.peso" | translate }} (Kg)
                            </th>
                            <th scope="col" style="width: 12%;">{{ "shoponline.PaginaCarrello.quantita" | translate }}
                            </th>
                            <th scope="col" style="width: 5%;">{{ "shoponline.PaginaCarrello.cancella" | translate }}
                            </th>
                            <th scope="col" style="width: 12%;">{{ "shoponline.PaginaCarrello.totale" | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let product of products">
                        <tr>
                            <td>
                                <a [routerLink]="['/shop/', product.ShortUrl]">
                                    <!--product.Title.replace(' ', '-')-->
                                    <img src="{{srcImg+product.ImageSrc}}" [alt]="'Perazzi'">
                                </a>
                            </td> 
                            <td>
                                <a [routerLink]="['/shop/', product.ShortUrl]">
                                    <!--product.Title.replace(' ', '-')-->
                                    <div class="gold-text m-0">{{ product.Title }}</div>
                                    <div *ngIf="product.Discount.MinOrder>1" class="m-0" style="font-size: 12px;">
                                        {{product.Discount.Title}} ({{product.Discount.Subtitle}})
                                    </div>
                                </a> 
                                <div class="mobile-cart-content row">
                                    <div class="col-md-3 col-xs-12 py-2">
                                        <div class="qty-box">
                                            <div class="input-group">
                                                <span class="input-group-prepend">
                                                    <button type="button" class="btn quantity-left-minus"
                                                        data-type="minus" (click)="decrement(product)">
                                                        <i class="ti-angle-left"></i>
                                                    </button>
                                                </span>
                                                <input type="text" name="quantity" class="form-control input-number"
                                                    disabled [(ngModel)]="product.Quantity">
                                                <span class="input-group-prepend">
                                                    <button type="button" class="btn quantity-right-plus"
                                                        data-type="plus" (click)="increment(product)">
                                                        <i class="ti-angle-right"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-xs-12 py-2">
                                        <h2 class="td-color">
                                            {{ getProductPrice(product) |
                                            currency:productService?.Currency.Currency:'symbol' }}
                                        </h2>
                                    </div>
                                    <div class="col-md-3 col-xs-12 py-2">
                                        <h2 class="td-color">
                                            <a (click)="removeItem(product)" class="icon">
                                                <i class="ti-close"></i>
                                            </a>
                                        </h2>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <h2>
                                    {{ getProductPrice(product) |
                                    currency:productService?.Currency.Currency:'symbol' }}
                                </h2>
                            </td>
                            <td>
                               <h2>{{ product.Weight | number : '1.3-3' }}</h2>
                            </td>
                            <td>
                                <div class="qty-box">
                                    <div class="input-group">
                                        <span class="input-group-prepend">
                                            <button type="button" class="btn quantity-left-minus" data-type="minus"
                                                (click)="decrement(product)">
                                                <i class="ti-angle-left"></i>
                                            </button>
                                        </span>
                                        <input type="text" name="quantity" class="form-control input-number" disabled
                                            [(ngModel)]="product.Quantity">
                                        <span class="input-group-prepend">
                                            <button type="button" class="btn quantity-right-plus" data-type="plus"
                                                (click)="increment(product)">
                                                <i class="ti-angle-right"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <a href="javascript:void(0)" (click)="removeItem(product)" class="icon">
                                    <i class="ti-close"></i>
                                </a>
                            </td>
                            <td>
                                <h2 class="td-color">
                                    {{ getSubtotal(product) | currency:productService?.Currency.Currency:'symbol'
                                    }}
                                </h2>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4" style="text-align: right;">{{"shoponline.PaginaCarrello.spedizione" |
                                translate }}: </td>
                            <td colspan="2"> {{"shoponline.PaginaCarrello.spedizione-messaggio" | translate }}</td>
                        </tr>
                        <tr>
                            <td style="padding-top: 20px; text-align: right;" colspan="4">{{
                                "shoponline.PaginaCarrello.prezzoTotale" | translate }} :</td>
                            <td style="padding-top: 20px;" colspan="2">
                                <h2> {{ getTotal | async | currency:productService?.Currency.Currency:'symbol'}}</h2>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="row cart-buttons" *ngIf='products.length' style="margin-bottom: 100px;">
            <div class="col-6">
                <a [routerLink]="['/shop']" class="btn btn-solid">{{ "shoponline.PaginaCarrello.continuaShopping" |
                    translate }}</a>
            </div>
            <div class="col-6">
                <a [routerLink]="['/checkout']" class="btn btn-solid">{{ "shoponline.PaginaCarrello.Checkout" |
                    translate }}</a>
            </div>
        </div>
    </div>
</section>
<!--section end-->