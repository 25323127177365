<ng-template #infoAssistance let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="text-center">{{ "info and assistance" | translate }}</h4>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body contact-page section-b-space">
            <div class="container p-0">
                <div class="col-lg-12">
                    <div class="contact-right">
                        <ul>
                            <li>
                                <div class="contact-icon">
                                    <img src="assets/images/icon/phone.png" alt="Generic placeholder image">
                                    <h6>{{ "contact us" | translate }}</h6>
                                </div>
                                <div class="media-body">
                                    <p>{{ "we answer the phone from 9.00 to 12.00 and from 15.30 to 18.30." | translate
                                        }}
                                    </p>
                                    <a href="tel:393386031713"><p class="btn btn-contact">+39 338 603 1713</p></a>
                                    <p>{{ "WE DO NOT RESPOND TO WHATSAPP OR SMS." | translate }}</p>
                                </div>
                            </li>
                            <li>
                                <div class="contact-icon">
                                    <img src="assets/images/icon/email.png" alt="Generic placeholder image">
                                    <h6>{{ "Email" | translate }}</h6>
                                </div>
                                <div class="media-body">
                                    <p>{{ "for information on the status of an order or shipments send an email to" |
                                        translate }}</p>
                                    <p class="btn btn-contact" [routerLink]="['/contacts']" (click)="modal.dismiss('Cross click')"> </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>