<!--<app-product-breadcrumb [title]="'menu.torta'" [breadcrumb]="'menu.torta'"></app-product-breadcrumb>-->


<section class="p-0 home section-home">
    <div class="container"><!--position-relative overflow-hidden text-center -->
        <div class="row" style="display: grid;">
            <div class="col-md-10 p-lg-3 mx-auto"><!--p-lg-5 mx-auto -->
                <div class="row" style="padding-top: 120px;">
                    <div class="col-md-6 my-auto p-4 text-center border-home">
                        <div class="slider-contain" style="justify-content: right;">
                            <div>
                                <h1 class="title-brown" style="text-shadow: 0px -1px 0px #fff, 2px 2px 0px #cc9551; color: #4e2724;">La
                                    Torta di Vigolo</h1>
                                <h3 class="pt-0" style="color: #212529;">
                                    <i>la nostra regina</i>
                                </h3>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-device box-shadow d-none d-md-block"></div>
            <div class="product-device product-device-2 box-shadow d-none d-md-block"></div>
        </div>
    </div>
</section>

<!--section start-->
<section class="contact-page section-b-space" style="margin-bottom: 50px; padding-top: 40px;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title-brown text-center">Da 35 anni la miglior torta di cioccolato.</h2>
            </div> 
            <div class="col-md-12 p-lg-3 mx-auto">
                <div>
                    <p class="text">
                        La storia di una magia in cucina. <br>
                        Ingredienti classici solo di grande qualità, certamente, ma solo ingredienti classici per la
                        regina delle torte.<br>
                        La ricetta, tenuta segreta e registrata, è una miscela che ha richiesto anni di esperimenti
                        prima di arrivare alla delizia che tutti potete assaggiare oggi.<br>
                        E poi il forno e la cura dei particolari......<br>
                        Dettagli, che fanno la differenza e che ci riempiono di orgoglio ogni volta che vediamo gli
                        occhi di chi la assaggia per la prima volta.....
                    </p>
                </div>
            </div>
            <div class="col-md-12 p-lg-3 mx-auto">
                <div class="row product_image_4">
                    <div class="col-6" *ngFor="let image of immaginiIngredienti; index as i">
                        <div class="collection-banner">
                            <img [defaultImage]="'assets/images/product/placeholder.jpg'" [lazyLoad]="image.src"
                                [alt]="image.alt" class="img-fluid">
                            <div class="contain-banner" style="padding-left: 60px; padding-bottom: 5px;">
                                <div style="background: rgba(255, 255, 255, 0.7);">
                                    <h4 class="p-2">{{image.alt}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->

<app-tap-to-top></app-tap-to-top>