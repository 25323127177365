import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-torta-di-vigolo',
  templateUrl: './torta-di-vigolo.component.html',
  styleUrls: ['./torta-di-vigolo.component.scss']
})
export class TortaDiVigoloComponent implements OnInit {

  immaginiIngredienti = [
    {
      src: "assets/perazzi/ingredients/Uova_bg.png",
      alt: "Uova"
    },
    {
      src: "assets/perazzi/ingredients/Burro_bg.png",
      alt: "Burro"
    },
    {
      src: "assets/perazzi/ingredients/Cioccolato_bg.png",
      alt: "Cioccolato"
    },
    {
      src: "assets/perazzi/ingredients/Farina_bg.png",
      alt: "Farina"
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
