import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router                  } from '@angular/router';
import { LoginService            } from 'src/app/dp-login/login.service';
import { InfoAssistanceComponent } from 'src/app/dp-modal/info-assistance/info-assistance.component';
import { Product                 } from 'src/app/dp-products/product.model';
import { NavService              } from 'src/app/dp-services/nav.service';
import { ProductService          } from 'src/app/dp-services/product.service';
;

@Component({
  selector: 'app-header-navbar',
  templateUrl: './header-navbar.component.html',
  styleUrls: ['./header-navbar.component.scss']
})

export class HeaderNavbarComponent implements OnInit {
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  @ViewChild("infoAssistance") infoAssistance: InfoAssistanceComponent;

  public loggedUser = false;
  public stick: boolean = false;
  public searchedProduct: Product[] = [];
  public searchInput = "";
  public numOfProducts = 0;

  constructor(private router: Router,
    public navServices: NavService,
    private loginService: LoginService,
    private productService: ProductService) {
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
    if (sessionStorage.getItem("SESSIONID") !== "" && 
        sessionStorage.getItem("SESSIONID") !== undefined && 
        sessionStorage.getItem("SESSIONID") !== null) {
      this.loggedUser = true;
    } else {
      this.loggedUser = false;
    }
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  logout() {
    var sessionID = {
      sessionID: sessionStorage.getItem("SESSIONID")
    };
    
    this.loginService.logout(sessionID).subscribe(()=>{
      sessionStorage.setItem("p-user-code", "");
      sessionStorage.setItem("SESSIONID", "");
      this.router.navigate(["/"]).then(() => window.location.reload());
    });
  }

  startSearch(event: any){
    if(event.target.value.length > 2) {
      this.searchedProduct = new Array<Product>();
      //event.target.value
      this.productService.getProducts.subscribe(response => {
        response.forEach(product => {
          if(product.Title.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())){
            this.searchedProduct.push(product);
          }
        })
      })
      
      if(event.target.value === "" || event.target.value === null){
        this.searchedProduct = new Array<Product>();
        this.searchInput = "";
      }
  
      this.numOfProducts = this.searchedProduct.length;
    }
  }

  changePage(product: Product){
    //[routerLink]="['/shop/', item.ShortUrl]"
    this.router.navigate(['/shop/', product.ShortUrl]).then(() => window.location.reload());
  }

  changeMenuPage(){
    if(this.searchedProduct.length > 0) {
      this.searchedProduct = new Array<Product>();
      this.numOfProducts = this.searchedProduct.length;
      this.searchInput = "";
    }
  }

  goFacebook() {
    window.open("https://www.facebook.com/tortadivigolo/"); 
  }
  goInstagram() {
    window.open("https://www.instagram.com/pasticceria_perazzi/");
  }
}