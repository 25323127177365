/**
 * GeoData API
 * Server RestAPI per la gestione di geodata.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * {\"columnName\": valore}
 */
export interface GormqueryStatWhereDTOComuneReadDTOValue { 
}
