<div class="product-top-filter">
    <div class="row">
        <div class="col-12">
            <div class="product-filter-content">
                <div class="search-count">
                    <h5>{{ "shoponline.ProdottiVisualizzati1" | translate }}-{{ products.length }} {{ "shoponline.di" | translate }} {{ paginate.totalItems }}</h5>
                </div>
                <div class="collection-view">
                    <ul>
                        <li (click)="setLayoutView('grid-view')">
                            <i class="fa fa-th grid-layout-view"></i>
                        </li>
                        <li (click)="setLayoutView('list-view')">
                            <i class="fa fa-list-ul list-layout-view"></i>
                        </li>
                    </ul>
                </div>
                <div class="collection-grid-view" *ngIf="layoutView == 'grid-view'">
                    <ul>
                        <li (click)="setGridLayout('col-lg-6')">
                            <img src="assets/images/icon/2.png" class="product-2-layout-view">
                        </li>
                        <li (click)="setGridLayout('col-lg-4')">
                            <img src="assets/images/icon/3.png" class="product-3-layout-view">
                        </li>
                        <li (click)="setGridLayout('col-lg-3')">
                            <img src="assets/images/icon/4.png" class="product-4-layout-view">
                        </li>
                        <li (click)="setGridLayout('col-lg-2')">
                            <img src="assets/images/icon/6.png" class="product-6-layout-view">
                        </li>
                    </ul>
                </div>
                <div class="product-page-filter">
                    <select (change)="sorting($event)" class="form-select">
                        <option value="">{{ "shoponline.Ordinamento" | translate }}</option>
                        <option value="a-z"  [selected]="sortBy == 'a-z'">{{ "shoponline.DallaAallaZ" | translate }}</option>
                        <option value="z-a"  [selected]="sortBy == 'z-a'">{{ "shoponline.DallaZallaA" | translate }}</option>
                        <option value="low"  [selected]="sortBy == 'low'">{{ "shoponline.PrezzoDalBasso" | translate }}</option>
                        <option value="high" [selected]="sortBy == 'high'">{{ "shoponline.PrezzodallAlto" | translate }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>