import { Component, OnDestroy, OnInit } from '@angular/core';
import { Product } from 'src/app/dp-products/product.model';
import { ProductService } from 'src/app/dp-services/product.service';
import { SliderService } from '../home-service/slider.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public products: Product[] = [];
  public productCollections: any[] = [];
  
  public logoSliderConfig: any = {
    loop: true,
    autoplay: true,
    center: true,
    dots: true,
    navSpeed: 70,
    navContainerClass: 'owl-nav',
    navClass: ['owl-prev', 'owl-next'],
    navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    nav: true,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 }
    }
  };
  public logos = [{
    id: 1,
    name: "Eataly",
    city: "Piacenza",
    subtitle: "Per chi se ne intende.",
    image: "/assets/perazzi/collaborazioni/Eataly.png"
  },{
    id: 2,
    name: "La Bottega della Genuità",
    city: "Castell'Arquato",
    subtitle: "Per chi ama la genuità.", 
    image: "/assets/perazzi/collaborazioni/bottegaDellaGenuita.jpg"
  },{
    id: 3,
    name: "Amendolara",
    city: "Piacenza, via Trento",
    subtitle: "Per chi vive Piacenza.", 
    image: "/assets/perazzi/collaborazioni/amendolara.png"
  },
  {
    id: 4,
    name: "Gilat Padana",
    city: "Piacenza",
    subtitle: "Per chi sa scegliere.",
    image: "/assets/perazzi/collaborazioni/Gilat.jpg"
  },{
    id: 5,
    name: "Eataly",
    city: "Torino",
    subtitle: "Per chi se ne intende.",
    image: "/assets/perazzi/collaborazioni/Eataly.png"
  },{
    id: 6,
    name: "Conad",
    city: "Salsomaggiore",
    subtitle: "Per la spesa di ogni giorno.", 
    image: "/assets/perazzi/collaborazioni/conad.png"
  },{
    id: 7,
    name: "Panetteria Repetti",
    city: "Piacenza",
    subtitle: "Per chi ama il mercato coperto.",
    image: "/assets/perazzi/collaborazioni/repetti.png"
  },{
    id: 8,
    name: "Amendolara",
    city: "Piacenza, via Dante",
    subtitle: "Per chi vive Piacenza.", 
    image: "/assets/perazzi/collaborazioni/amendolara.png"
  },{
    id: 9,
    name: "Eataly",
    city: "Milano",
    subtitle: "Per chi se ne intende.",
    image: "/assets/perazzi/collaborazioni/Eataly.png"
  },
  {
    id: 10,
    name: "Saiani",
    city: "Cortemaggiore",
    subtitle: "Per chi sa scegliere.",
    image: "/assets/perazzi/collaborazioni/Saiani.jpg"
  },{
    id: 11,
    name: "Sim Salam Inn",
    city: "Piacenza",
    subtitle: "Per chi mangia piacentino.",
    image: "/assets/perazzi/collaborazioni/SimSalamInn.jpg"
  },{
    id: 12,
    name: "Eataly",
    city: "Torino",
    subtitle: "Per chi se ne intende.",
    image: "/assets/perazzi/collaborazioni/Eataly.png"
  }
];

  // Collection banner
  public collections = [{
    image: 'assets/perazzi/home-collection/pasticcini.jpg',
    save: 'Brioches, biscotti e pasticcini',
    title: 'Dolcezze',
    link: '/home/left-sidebar/collection/furniture'
  }, {
    image: 'assets/perazzi/home-collection/pizzette.jpg',
    save: 'Focacce e pizze',
    title: 'Pizze',
    link: '/home/left-sidebar/collection/furniture'
  }, {
    image: 'assets/perazzi/home-collection/torte.jpg',
    save: 'Non solo la classica Torta di Vigolo, ma anche crostate e tutte le torte che vi vengono in mente',
    title: 'Torte',
    link: '/home/left-sidebar/collection/furniture'
  }, {
    image: 'assets/perazzi/home-collection/paste.jpg',
    save: 'Anolini, tortelli e il meglio delle paste emiliane',
    title: 'Paste',
    link: '/home/left-sidebar/collection/furniture'
  }]

  constructor(
    public productService: ProductService,
    private slidersService: SliderService) {

    // Categories
    this.productService.getProducts.subscribe(response => {
      //this.products = response.filter(item => item.Category == 'Fotocamere digitali' || item.Category == 'Obiettivi');
      this.products = response;
      // Get Product Collection
      for (let item of this.products) {
        if (item.Collection !== undefined && item.Collection !== null) {
          item.Collection.filter((collection) => {
            const index = this.productCollections.indexOf(collection);
            if (index === -1) this.productCollections.push(collection);
          })
        }
      }
    });
  }

  ngOnInit(): void { 
  }

  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
  }
  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.Collection !== undefined && item.Collection !== null) {
        if (item.Collection.find(i => i === collection)) {
          return item
        }
      }
    })
  } 
}
