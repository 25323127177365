/**
 * GeoData API
 * Server RestAPI per la gestione di geodata.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { DTOComuneReadDTO } from '../model/dTOComuneReadDTO'; 
import { GinServerFormResponseDTOComuneReadDTO } from '../model/ginServerFormResponseDTOComuneReadDTO';
import { GinServerSearchResponseDTOComuneReadDTO } from '../model/ginServerSearchResponseDTOComuneReadDTO';
import { GormquerySearchRequestDTOComuneReadDTO } from '../model/gormquerySearchRequestDTOComuneReadDTO';
import { GormquerySearchResponseDTOComuneReadDTO } from '../model/gormquerySearchResponseDTOComuneReadDTO';

import { BASE_PATH }                              from '../variables';
import { Configuration }                          from '../configuration';


@Injectable()
export class ComuneService {

    protected basePath = 'https://services.wedare.app/geodata/api/v1/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration; 
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * CheckForComuniUpdate
     * Controlla se ci sono aggiornamenti dei comuni dalla sorgente Istat
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public comuneCheckForComuniUpdateGet(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public comuneCheckForComuniUpdateGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public comuneCheckForComuniUpdateGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public comuneCheckForComuniUpdateGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (OAuth2Application) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<string>(`${this.basePath}/Comune/CheckForComuniUpdate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetByKey
     * Ottieni un singolo Comune filtrato per chiave
     * @param ID chiave Comune
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public comuneGetByKeyIDGet(ID: number, observe?: 'body', reportProgress?: boolean): Observable<GinServerFormResponseDTOComuneReadDTO>;
    public comuneGetByKeyIDGet(ID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GinServerFormResponseDTOComuneReadDTO>>;
    public comuneGetByKeyIDGet(ID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GinServerFormResponseDTOComuneReadDTO>>;
    public comuneGetByKeyIDGet(ID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ID === null || ID === undefined) {
            throw new Error('Required parameter ID was null or undefined when calling comuneGetByKeyIDGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2Application) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GinServerFormResponseDTOComuneReadDTO>(`${this.basePath}/Comune/GetByKey/${encodeURIComponent(String(ID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetComuni
     * Ottieni la lista dei comuni
     * @param regione filtrare per regione, supporta % per like
     * @param provincia filtrare per provincia, supporta % per like
     * @param comune filtrare per comune, supporta % per like
     * @param pageNum numero della pagina
     * @param pageElements elementi della pagina
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public comuneGetComuniGet(regione?: string, provincia?: string, comune?: string, pageNum?: number, pageElements?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DTOComuneReadDTO>>;
    public comuneGetComuniGet(regione?: string, provincia?: string, comune?: string, pageNum?: number, pageElements?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DTOComuneReadDTO>>>;
    public comuneGetComuniGet(regione?: string, provincia?: string, comune?: string, pageNum?: number, pageElements?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DTOComuneReadDTO>>>;
    public comuneGetComuniGet(regione?: string, provincia?: string, comune?: string, pageNum?: number, pageElements?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (comune !== undefined && comune !== null) {
            queryParameters = queryParameters.set('comune', <any>comune);
        }
        if (pageNum !== undefined && pageNum !== null) {
            queryParameters = queryParameters.set('pageNum', <any>pageNum);
        }
        if (pageElements !== undefined && pageElements !== null) {
            queryParameters = queryParameters.set('pageElements', <any>pageElements);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2Application) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<DTOComuneReadDTO>>(`${this.basePath}/Comune/GetComuni`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetProvince
     * Ottieni la lista delle province
     * @param regione filtrare per regione, supporta % per like
     * @param provincia filtrare per provincia, supporta % per like
     * @param pageNum numero della pagina
     * @param pageElements elementi della pagina
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public comuneGetProvinceGet(regione?: string, provincia?: string, pageNum?: number, pageElements?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DTOComuneReadDTO>>;
    public comuneGetProvinceGet(regione?: string, provincia?: string, pageNum?: number, pageElements?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DTOComuneReadDTO>>>;
    public comuneGetProvinceGet(regione?: string, provincia?: string, pageNum?: number, pageElements?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DTOComuneReadDTO>>>;
    public comuneGetProvinceGet(regione?: string, provincia?: string, pageNum?: number, pageElements?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (pageNum !== undefined && pageNum !== null) {
            queryParameters = queryParameters.set('pageNum', <any>pageNum);
        }
        if (pageElements !== undefined && pageElements !== null) {
            queryParameters = queryParameters.set('pageElements', <any>pageElements);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2Application) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<DTOComuneReadDTO>>(`${this.basePath}/Comune/GetProvince`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetRegioni
     * Ottieni la lista delle province
     * @param regione filtrare per regione, supporta % per like
     * @param pageNum numero della pagina
     * @param pageElements elementi della pagina
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public comuneGetRegioniGet(regione?: string, pageNum?: number, pageElements?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public comuneGetRegioniGet(regione?: string, pageNum?: number, pageElements?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public comuneGetRegioniGet(regione?: string, pageNum?: number, pageElements?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public comuneGetRegioniGet(regione?: string, pageNum?: number, pageElements?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (pageNum !== undefined && pageNum !== null) {
            queryParameters = queryParameters.set('pageNum', <any>pageNum);
        }
        if (pageElements !== undefined && pageElements !== null) {
            queryParameters = queryParameters.set('pageElements', <any>pageElements);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2Application) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/Comune/GetRegioni`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Query
     * Ottieni la lista degli Comune filtrati
     * @param comune query Comune
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public comuneQueryPost(comune: GormquerySearchRequestDTOComuneReadDTO, observe?: 'body', reportProgress?: boolean): Observable<GormquerySearchResponseDTOComuneReadDTO>;
    public comuneQueryPost(comune: GormquerySearchRequestDTOComuneReadDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GormquerySearchResponseDTOComuneReadDTO>>;
    public comuneQueryPost(comune: GormquerySearchRequestDTOComuneReadDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GormquerySearchResponseDTOComuneReadDTO>>;
    public comuneQueryPost(comune: GormquerySearchRequestDTOComuneReadDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (comune === null || comune === undefined) {
            throw new Error('Required parameter comune was null or undefined when calling comuneQueryPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2Application) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GormquerySearchResponseDTOComuneReadDTO>(`${this.basePath}/Comune/Query`,
            comune,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search
     * Search tra tabella Comune
     * @param order0Dir dir
     * @param draw draw
     * @param order0Index index
     * @param start start
     * @param length length
     * @param regione regione
     * @param provincia provincia
     * @param denomita denomita
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public comuneSearchGet(order0Dir?: string, draw?: string, order0Index?: string, start?: string, length?: string, regione?: string, provincia?: string, denomita?: string, observe?: 'body', reportProgress?: boolean): Observable<GinServerSearchResponseDTOComuneReadDTO>;
    public comuneSearchGet(order0Dir?: string, draw?: string, order0Index?: string, start?: string, length?: string, regione?: string, provincia?: string, denomita?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GinServerSearchResponseDTOComuneReadDTO>>;
    public comuneSearchGet(order0Dir?: string, draw?: string, order0Index?: string, start?: string, length?: string, regione?: string, provincia?: string, denomita?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GinServerSearchResponseDTOComuneReadDTO>>;
    public comuneSearchGet(order0Dir?: string, draw?: string, order0Index?: string, start?: string, length?: string, regione?: string, provincia?: string, denomita?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (order0Dir !== undefined && order0Dir !== null) {
            queryParameters = queryParameters.set('order[0][dir]', <any>order0Dir);
        }
        if (draw !== undefined && draw !== null) {
            queryParameters = queryParameters.set('draw', <any>draw);
        }
        if (order0Index !== undefined && order0Index !== null) {
            queryParameters = queryParameters.set('order[0][index]', <any>order0Index);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start);
        }
        if (length !== undefined && length !== null) {
            queryParameters = queryParameters.set('length', <any>length);
        }
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (denomita !== undefined && denomita !== null) {
            queryParameters = queryParameters.set('denomita', <any>denomita);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2Application) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GinServerSearchResponseDTOComuneReadDTO>(`${this.basePath}/Comune/Search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
