import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Product } from 'src/app/dp-products/product.model';
import { OrderService } from 'src/app/dp-services/order.service';
import { ProductService } from 'src/app/dp-services/product.service';
import { CheckoutService } from '../cart-service/checkout.service';
import { Country, State } from '../cart-service/countries.model';
import { PaymentService } from '../cart-service/payment.service';
import { ComuneService, DTOComuneReadDTO } from 'src/app/geodata-service';

@Component({
  selector: 'app-product-checkout',
  templateUrl: './product-checkout.component.html',
  styleUrls: ['./product-checkout.component.scss']
})
export class ProductCheckoutComponent implements OnInit {
  paymentHandler: any = null;
  repeatTrue: boolean;
  repeatValue: string;
  openStripe: boolean;
  invalidIVA = false;
  errorText = "";
  couponID: null | number = null;
  couponAmount: number = 0;
  stripekey: string = "";

  public checkoutForm: FormGroup;
  public products: Product[] = [];
  //public payPalConfig?: IPayPalConfig;
  public payment: string = 'Stripe';
  public amount: any;
  public countries: Country[];
  public province: DTOComuneReadDTO[];
  public richiestaFattura: boolean = false;
  fatturaValida: boolean;

  garanzia4 = 0;
  newCustomerCheckout: any;
  newOrder: any;

  spedizioneScelta = 0;

  constructor(
    private fb: FormBuilder,
    public productService: ProductService,
    private orderService: OrderService,
    private paymentService: PaymentService,
    private coService: CheckoutService,
    private geodata: ComuneService,
  ) {
    this.checkoutForm = this.fb.group({
      firstname: ['', [Validators.required, Validators.maxLength(50)]],
      lastname: ['', [Validators.required, Validators.maxLength(50)]],
      phone: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(30)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      address: ['', [Validators.required, Validators.maxLength(70)]],
      country: ['', [Validators.required]],
      town: ['', [Validators.required, Validators.maxLength(30)]],
      state: ['', [Validators.nullValidator]],
      postalcode: ['', [Validators.required, Validators.maxLength(10)]],
      companyname: ["", [Validators.maxLength(70)]],
      vat: ['', [Validators.pattern('[0-9]+'), Validators.maxLength(30)]],
      vatcountry: ['', [Validators.maxLength(30)]],
      sdicode: ['', [Validators.maxLength(30)]],
      couponcode: ['', [Validators.maxLength(30)]],
      couponamount: [0, [Validators.nullValidator]],
    });

    this.productService.cartItems.toPromise().then(response => this.products = response);
  }

  userCode: string;
  userData: any;

  ngOnInit(): void {
    this.repeatValue = "";
    this.coService.getCountries().toPromise().then(data => {
      this.countries = data;
    });

    this.geodata.comuneGetProvinceGet().toPromise().then(res => {
      if (res) {
        this.province = res;
      }
    });


    this.coService.getStripeToken().toPromise().then(response => {
      if (response.key !== undefined) {
        this.stripekey = response.key;
      }
    });

    this.products.forEach(product => {
      if (product.Warranty === true) {
        this.garanzia4 = this.garanzia4 + this.calculationWarranty(product) * product.Quantity;
      }
    });

    this.calcolaSpedizione();
    this.userCode = localStorage.getItem('p-user-code');
    this.orderService.isExistUserCode(this.userCode).subscribe(response => {
      if (response !== "Nessun utente trovato" && response !== undefined && response.ID != 0) {
        this.userData = response;
        this.checkoutForm.get('lastname').setValue(this.userData.LastName);
        this.checkoutForm.get('firstname').setValue(this.userData.FirstName);
        this.checkoutForm.get('address').setValue(this.userData.Address);
        this.checkoutForm.get('town').setValue(this.userData.Town);
        this.checkoutForm.get('state').setValue(this.userData.StateProvince);
        this.checkoutForm.get('email').setValue(this.userData.Email);
        this.checkoutForm.get('phone').setValue(this.userData.Phone);
        this.checkoutForm.get('postalcode').setValue(this.userData.PostalCode);
        //this.checkoutForm.get('companyname').setValue(this.userData.CompanyName);
        //this.checkoutForm.get('sdicode').setValue(this.userData.SDIcode);
        this.checkoutForm.get('couponcode').setValue('');
        this.checkoutForm.get('vat').setValue('');
        this.checkoutForm.get('vatcountry').setValue('');
        if (this.userData.Country !== "" && this.userData.Country !== null && this.userData.Country !== undefined) {
          this.calcolaSpedizione()
        }
      }
    });
    this.getTotal.toPromise().then(amount => { this.amount = amount; this.calcolaSpedizione() });
  }

  public get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount(this.spedizioneScelta, this.couponAmount);
  }

  checkout(typeofpayment: string) {
    this.newCustomerCheckout = {
      LastName: this.checkoutForm.get('lastname')?.value,
      FirstName: this.checkoutForm.get('firstname')?.value,
      Address: this.checkoutForm.get('address')?.value,
      Town: this.checkoutForm.get('town')?.value,
      StateProvince: this.checkoutForm.get('state')?.value,
      Country: this.checkoutForm.get('country')?.value,
      Email: this.checkoutForm.get('email')?.value,
      Phone: Number(this.checkoutForm.get('phone')?.value),
      PostalCode: this.checkoutForm.get('postalcode')?.value,
      CompanyName: this.checkoutForm.get('companyname')?.value,
      VAT: this.checkoutForm.get('vatcountry')?.value+this.checkoutForm.get('vat')?.value,
      CustomerCode: this.userCode,
      SDIcode: this.checkoutForm.get('sdicode').value,
      CouponCode: this.checkoutForm.get('couponcode').value,
      CouponAmount: this.checkoutForm.get('couponamount').value,
    }
    if (this.userData !== undefined) {
      if (this.userData.FirstName === this.newCustomerCheckout.FirstName &&
        this.userData.LastName === this.newCustomerCheckout.LastName) {
        this.orderService.updateUserData(this.newCustomerCheckout, this.userData.ID).subscribe(updateUser => {
          this.createOrder(this.userData.ID, this.newCustomerCheckout.Country, this.amount + this.garanzia4 + this.spedizioneScelta - this.couponAmount, typeofpayment, this.products);
        })
      } else {
        this.orderService.createUserCode().subscribe(newcode => {
          var vecchioCodice = localStorage.getItem("p-user-code");
          var nuovoCodice = newcode;
          this.orderService.updateUserCode(nuovoCodice, vecchioCodice).toPromise().then(codice => {
            localStorage.setItem("p-user-code", newcode);
            this.newCustomerCheckout.CustomerCode = newcode;
            this.orderService.saveUserData(this.newCustomerCheckout).subscribe(responseUserID => {
              this.createOrder(responseUserID, this.newCustomerCheckout.Country, this.amount + this.garanzia4 + this.spedizioneScelta - this.couponAmount, typeofpayment, this.products);
            })
          })
        })
      }
    } else {
      this.newCustomerCheckout.CustomerCode = this.userCode;
      this.orderService.saveUserData(this.newCustomerCheckout).subscribe(responseUserID => {
        this.createOrder(responseUserID, this.newCustomerCheckout.Country, this.amount + this.garanzia4 + this.spedizioneScelta - this.couponAmount, typeofpayment, this.products);
      })
    }
  }

  createOrder(userid: any, country: string, orderTotal: any, typeofpayment: string, prodotti: any[]) {
    this.newOrder = {
      CustomerID: Number(userid),
      CountryOfOrigin: country,
      OrderTotal: Number(orderTotal.toFixed(2)),
      DeliveryPrice: Number(this.spedizioneScelta.toFixed(2)),
      CouponID: this.couponID,
      CouponAmount: this.couponAmount
    }
    this.orderService.saveOrder(this.newOrder).subscribe(responseOrder => {
      var idOrdine = responseOrder;
      if (idOrdine !== undefined) {
        this.makePayment(orderTotal.toFixed(2), idOrdine, prodotti, country);
      }
    });
  }

  extendWarranty(product) {
    this.products.forEach((el, i) => {
      if (el.ID === product.ID) {
        el.Warranty = !el.Warranty;
        if (el.Warranty === true) {
          this.garanzia4 = this.garanzia4 + this.calculationWarranty(product) * product.Quantity;
        } else {
          this.garanzia4 = this.garanzia4 - this.calculationWarranty(product) * product.Quantity;
        }
      }
    })
  }

  calculationWarranty(product) {
    var garanzia = (product.Price / 100) * 3.5;
    return Number(garanzia.toFixed(2));
  }

  insuredShipping() {
    var totalShipping = 0
    totalShipping = this.products.map(a => (a.Price - a.Discount) * a.Quantity).reduce((a, b) => a + b, 0);
    totalShipping = (totalShipping / 100) * 1.2 + 10.5;
    totalShipping = Number(totalShipping.toFixed(2));
    return totalShipping;
  }

  makePayment(totAmount: any, idOrdine: any, prodotti: any, country: string) {
    //configurazione stripe
    console.log("inizio configurazione stripe");
    console.log("pagamento di: ", totAmount, " euro");
    let payload = {
      "amount": totAmount * 100,
      "currency": "EUR",
      "email": this.newCustomerCheckout.Email,
      "order_id": idOrdine,
      "name": this.newCustomerCheckout.FirstName,
      "surname": this.newCustomerCheckout.LastName,
      "country": this.newCustomerCheckout.Country,
      "city": this.newCustomerCheckout.Town,
      "postal_code": this.newCustomerCheckout.PostalCode,
      "state": this.newCustomerCheckout.StateProvince,
      "address": this.newCustomerCheckout.Address,
      'sdicode': this.newCustomerCheckout.SDIcode,
    }
    this.paymentService.stripeCallback(payload).subscribe((link: string) => {
      if (link) {
        window.location.href = link;
        // window.open(link, "_blank");
      }
    }, err => {
      window.location.href = err.error.tex;
      //window.open(err.error.text, "_blank");
    })
  }

  doSomethingWhenStripePopUpCloses(idOrdine: any, country: string) {
    var newPayment = {
      OrderID: idOrdine,
      PaymentType: "Stripe",
      Country: country,
      Status: "annullato"
    }
    this.paymentService.addPayment(newPayment).subscribe(responsePayment => {
      //console.log(responsePayment)
    })
  }

  repeatePswd(value: any, value2: any) {
    if (value === 1) {
      this.repeatTrue = false;
      this.repeatValue = value2.target.value;
      if (this.repeatValue === this.checkoutForm.get('pswd')?.value) {
        this.repeatTrue = true;
      } else {
        this.repeatTrue = false;
      }
    } else {
      if (this.repeatValue !== "") {
        if (this.repeatValue === this.checkoutForm.get('pswd')?.value) {
          this.repeatTrue = true;
        } else {
          this.repeatTrue = false;
        }
      }
    }
  }

  disablePlaceOrder() {
    if (this.richiestaFattura === true) {
      this.checkoutForm.get('vat').setValidators([Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(30)]);
      this.checkoutForm.get('vatcountry').setValidators([Validators.required, Validators.maxLength(30)]);
      this.checkoutForm.get('sdicode').setValidators([Validators.required, Validators.maxLength(30)]);
      this.checkoutForm.get('companyname').setValidators([Validators.required, Validators.maxLength(70)]);
    } else {
      this.checkoutForm.get('vat').clearValidators();
      this.checkoutForm.get('vatcountry').clearValidators();
      this.checkoutForm.get('sdicode').clearValidators();
      this.checkoutForm.get('companyname').clearValidators(); 
    }

    if (this.richiestaFattura === true && this.checkoutForm.valid) {
      return false;
    } else if (this.richiestaFattura === false && this.checkoutForm.valid) {
      return false;
    }
    return true;
  }

  verificaIVA() {
    const VAT = this.checkoutForm.get('vat')?.value;
    const VATcountry = this.checkoutForm.get('vatcountry')?.value;

    if (VAT !== "" && VATcountry !== "") {
      this.invalidIVA = false;
      this.coService.checkIva(VATcountry, VAT).toPromise().then(response => {
        if (response !== undefined) {
          this.fatturaValida = response.isValid;
          if (this.fatturaValida === true && response.name !== undefined) {
            this.checkoutForm.get('companyname').setValue(response.name)
          }
        }
      })
    } else {
      this.invalidIVA = true;
    }
  }

  calcolaSpedizione() {
    var peso = 0;
    var paese = this.checkoutForm.get('country')?.value;
    var state = this.checkoutForm.get('state')?.value;
    var provincia;
    if (paese === 'Italia') {
      this.checkoutForm.controls['state']?.setValidators(Validators.required);
      provincia = this.province?.find(pr => pr.denomIta === state);
    } else {
      this.checkoutForm.get('state')?.clearValidators();
    }
    this.checkoutForm.controls['state'].updateValueAndValidity();

    this.products.forEach(el => peso += (el.Weight * el.Quantity));

    this.coService.costoSpedizione(peso, paese).toPromise().then(response => {
      if (response && response !== "") { 
        if (provincia && (provincia?.denomRegione === "Sicilia" || provincia?.denomRegione === "Sardegna")) {
          this.spedizioneScelta = response.Prezzo + 1;
        } else {
          this.spedizioneScelta = response.Prezzo;
        }
      } else {
        this.spedizioneScelta = undefined;
      }
    })
  }

  verificaCoupon() {
    this.errorText = "";
    const email = this.checkoutForm.get('email')?.value;
    const codice = this.checkoutForm.get('couponcode')?.value
    this.orderService.checkCoupon(email, codice, this.amount).toPromise().then(response => {
      if (response.ID !== undefined) {
        this.couponID = response.ID;
        if (response.DiscountTypeID === 1) {
          //sconto fisso
          this.couponAmount = response.Amount;
        } else if (response.DiscountTypeID === 2) {
          //percentuale
          this.couponAmount = Number(((this.amount / 100) * response.Amount).toFixed(2));
        }
        this.checkoutForm.get('couponamount').setValue(this.couponAmount);
      } else {
        this.errorText = response;
      }
    })
  }

  alertClose() {
    this.errorText = "";
  }

  changeEmail() {
    if (this.checkoutForm.get('email').value === "") {
      this.couponAmount = 0;
      this.checkoutForm.get('couponamount').setValue(0);
      this.checkoutForm.get('couponcode')?.setValue("");
      this.errorText = "";
    }
  }
}
