<app-product-breadcrumb [title]="'Checkout'" [breadcrumb]="'Checkout'"></app-product-breadcrumb>

<!-- section start -->
<section class="p-0" *ngIf="errore && carricamentoDati">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="error-section">
                    <h1>404</h1>
                    <h2>page not found</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->

<!-- thank-you section start -->
<section class="section-b-space" *ngIf="!errore">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="success-text">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <h3>{{ "shoponline.PaginaCheckoutSuccess.Grazie" | translate }} {{ orderDetails.order.Customer }} {{ "shoponline.PaginaCheckoutSuccess.perAverOrdinato" |
                        translate }}.</h3>
                    <p>{{ "shoponline.PaginaCheckoutSuccess.AbbiamoRicevutoTuoOrdine" | translate }} <b>{{ orderDetails.order.ID }}</b>.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->

<!-- order-detail section start -->
<section class="section-b-space pt-0" *ngIf="!errore">
    <div class="container">
        <div class="row">

            <div class="col-12 my-4">
                <div class="product-description border-product">
                    <div class="container important-detail">
                        <div class="product-order text-center">
                            <h4 style="line-height: 30px;">{{ "shoponline.PaginaCheckoutSuccess.SiRicordaChePerEvasioneOordine" | translate }} <b>€ {{ orderDetails.order.TotalPrice
                                    }}</b> <br>
                                {{ "shoponline.PaginaCheckoutSuccess.TrasferireA" | translate }}: <b> Pasticceria Perazzi </b> <br>
                                IBAN: <b>AAAAAAAAA0980980980</b> <br> </h4>

                            <h4 style="color: #ff4c3b; line-height: 24px;">{{ "shoponline.PaginaCheckoutSuccess.SeNonRiceviamoBonifico" |
                                translate }} <b>{{ "shoponline.PaginaCheckoutSuccess.entro3giorni" | translate }}</b> {{ "shoponline.PaginaCheckoutSuccess.dallaDataDellOrdine" | translate }}. <br>
                                {{ "shoponline.PaginaCheckoutSuccess.PuoiContattarciVia" | translate }} &nbsp;<b><i>{{ "info-perazzi.email" | translate }} </i></b> &nbsp;{{
                                "shoponline.PaginaCheckoutSuccess.perQualsiasiEsigenza" | translate }}.</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="product-order text-center">
                    <p style="line-height: 18px;"> {{ "We will handle the" | translate }} <b>{{ orderDetails.order.ID
                            }}</b> {{ "as soon as possible" | translate }}. <br>
                        {{ "You will receive emails at upcoming updates so you are always on top of things" | translate
                        }}.</p>
                </div>
            </div>

            <div class="col-12 pt-4">
                <div class="product-order text-center">
                    <h3>{{ "your order details" | translate }}</h3>
                </div>
            </div>
            <div class="col-lg-6 mt-3">
                <div class="product-order">
                    <!--elencare tutti i prodotti ordinati-->
                    <div class="row product-order-detail">
                        <div class="col-6 order_detail">
                            <div>
                                <h4>{{ "product" | translate }}</h4>
                            </div>
                        </div>
                        <div class="col-1 order_detail">
                            <div>
                                <h4>{{ "qty" | translate }}</h4>
                            </div>
                        </div>
                        <div class="col-3 order_detail">
                            <div>
                                <h4>{{ "price" | translate }}</h4>
                            </div>
                        </div>
                        <div class="col-2 order_detail">
                            <div>
                                <h4>{{ "warranty" | translate }}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row product-order-detail" *ngFor="let product of orderDetails.order.Products">
                        <div class="col-6 order_detail">
                            <div>
                                <h5>{{product.ProductName}}</h5>
                            </div>
                        </div>
                        <div class="col-1 order_detail">
                            <div>
                                <h5>{{product.Quantity}}</h5>
                            </div>
                        </div>
                        <div class="col-3 order_detail">
                            <div>
                                <h5>{{product.Price}} €</h5>
                            </div>
                        </div>
                        <div class="col-2 order_detail">
                            <div>
                                <h5>{{product.Warranty4 * product.Quantity}} €</h5>
                            </div>
                        </div>
                    </div>
                    <div class="total-sec">
                        <ul>
                            <li>{{ "subtotal" | translate }} <span>{{subtotalOrderPrice}}</span>
                                <!--<span>{{ orderDetails.totalAmount | currency:productService?.Currency.currency:'symbol' }}</span>-->
                            </li>
                            <li>{{ "shipping price" | translate }} <span>{{orderDetails.order.DeliveryPrice}}</span>
                            </li>
                            <!--<li>{{ "tax" | translate }} <span>$00.00</span></li>-->
                        </ul>
                    </div>
                    <div class="final-total">
                        <h3>{{ "total" | translate }}<span>€ {{ orderDetails.order.TotalPrice }}</span>
                            <!--<span>{{ orderDetails.totalAmount | currency:productService?.Currency.currency:'symbol' }}</span>-->
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mt-3">
                <div class="row order-success-sec">
                    <div class="col-sm-6">
                        <h4>{{ "summery" | translate }}</h4>
                        <ul class="order-detail">
                            <li>{{ "order ID" | translate }}: {{ orderDetails.order.ID }}</li>
                            <li>{{ "Order Date" | translate }}: {{ orderDetails.order.CreatedAt }}</li>
                            <li>{{ "Order Total" | translate }}: € {{ orderDetails.order.TotalPrice }}
                                <!-- | currency:productService?.Currency.currency:'symbol' -->
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-6">
                        <h4>{{ "shipping address" | translate }}</h4>
                        <ul class="order-detail">
                            <li>{{ orderDetails.customer.Address }}</li>
                            <li>{{ orderDetails.customer.Town }} ({{orderDetails.customer.StateProvince}})</li>
                            <li>{{ orderDetails.customer.Country }}, {{ orderDetails.customer.PostalCode }}</li>
                            <!--<li>Contact No. {{ orderDetails.shippingDetails.phone }}</li>-->
                        </ul>
                    </div>

                    <div class="col-sm-12 payment-mode mt-3">
                        <h4>{{ "payment method" | translate }}</h4>
                        <p>{{ "bank transfer" | translate }}</p>
                    </div>
                    <!--<div class="col-md-12">
                        <div class="delivery-sec">
                            <h3>{{ "expected date of delivery" | translate }}</h3>
                            <h2>{{ orderDeliverydate | date:'dd-MM-yyyy'}}</h2>
                        </div>
                    </div>-->
                </div>
            </div>

        </div>
    </div>
</section>
<!-- Section ends -->

<div class="row p-2 m-2">
    <div class="col-12 text-center pb-4">
        <a [routerLink]="['/']" class="btn btn-solid">{{ "home" | translate }}</a>
    </div>
</div>