import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();

  constructor() { }

  ngOnInit(): void {
  }

  goFacebook() {
    window.open("https://www.facebook.com/tortadivigolo/"); 
  }
  goInstagram() {
    window.open("https://www.instagram.com/pasticceria_perazzi/");
  }
  goTwitter() {
    window.open("https://twitter.com/LaTortadiVigolo");
  }
  goTripadvisor() {
    window.open("https://www.tripadvisor.it/Restaurant_Review-g1131341-d12785929-Reviews-Pasticceria_Panetteria_Perazzi-Castell_Arquato_Province_of_Piacenza_Emilia_Roma.html?m=19904");
  }
}
