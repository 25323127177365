import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DiscountPipe } from 'src/app/dp-pipe/discount.pipe';
import { Product } from 'src/app/dp-products/product.model';
import { ProductService } from 'src/app/dp-services/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-cart',
  templateUrl: './product-cart.component.html',
  styleUrls: ['./product-cart.component.scss']
})

export class ProductCartComponent implements OnInit {

  public products: Product[] = [];

  public srcImg = environment.srcImg;

  garanzia4 = 0;
  checked1 = false;

  constructor(public productService: ProductService, private pipe: DiscountPipe) {
    this.productService.cartItems.subscribe(response => this.products = response);
  }

  ngOnInit(): void {
  }

  public get getTotal(): Observable<number> {
    var totale = this.productService.cartTotalAmount(0, 0);
    return totale;
  }

  insuredShipping() {
    var totalShipping = 0
    this.getTotal.toPromise().then(res => {
      totalShipping = res;
      totalShipping = (totalShipping / 100) * 1.2 + 10.5;
      totalShipping = Number(totalShipping.toFixed(2));
      return totalShipping;
    })
    return totalShipping;
  }

  getProductPrice(product: Product): any {
    var prezzo = this.pipe.transform(product.Price, product)
    return prezzo;
  }

  getSubtotal(product: Product): any {
    var prezzo = this.pipe.transform(product.Price, product)
    return prezzo * product.Quantity;
  }

  // Increament
  increment(product, qty = 1) {
    this.productService.updateCartQuantity(product, qty);
    //this.spedizioneAssicurata.price = this.insuredShipping(); 
  }

  // Decrement
  decrement(product, qty = -1) {
    this.productService.updateCartQuantity(product, qty);
    //this.spedizioneAssicurata.price = this.insuredShipping(); 
  }

  public removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  extendWarranty(product) {
    //product.Warranty = !product.Warranty;
    this.products.forEach((el, i) => {
      if (el.ID === product.ID) {
        el.Warranty = !el.Warranty;
        if (el.Warranty === true) {
          this.garanzia4 = this.garanzia4 + this.calculationWarranty(product) * el.Quantity;
        } else {
          this.garanzia4 = this.garanzia4 - this.calculationWarranty(product) * el.Quantity;
        }
      }
    });

    if (product.Warranry4) {
      var cartData = {
        CustomerCode: localStorage.getItem("p-user-code"),
        ProductID: product.ID,
        Quantity: product.Quantity,
        Warranty4: this.calculationWarranty(product) * product.Quantity,
      }
      this.productService.UpdateCart(cartData).subscribe(response => {
      });
    } else {
      var cartData = {
        CustomerCode: localStorage.getItem("p-user-code"),
        ProductID: product.ID,
        Quantity: product.Quantity,
        Warranty4: this.calculationWarranty(product) * product.Quantity
      }
      this.productService.UpdateCart(cartData).subscribe(response => {
      });
    }
    this.productService.updateCartWarranty(product, this.calculationWarranty(product) * product.Quantity, product.Warranty)
  }

  calculationWarranty(product) {
    var garanzia = (product.Price / 100) * 3.5;
    return Number(garanzia.toFixed(2));
  }
}