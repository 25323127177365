import { Component, OnInit } from '@angular/core';
import { CompareSlider } from './compare-slider';

@Component({
  selector: 'app-product-compare',
  templateUrl: './product-compare.component.html',
  styleUrls: ['./product-compare.component.scss']
})
export class ProductCompareComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public CompareSliderConfig: any = CompareSlider;
}
