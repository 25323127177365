<header class="sticky header-tools"><!-- header-tools-->
  <div class="mobile-fix-option"></div>
   
  <!--menu principale-->
  <div class=" py-0 my-0">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="main-menu">
            <div class="menu-left">
              <div class="toggle-nav" (click)="mainMenuToggle()" style="padding-right: 25px;">
                <i class="fa fa-bars sidebar-bar"></i>
              </div>
              <div class="brand-logo">
                <a routerLink="/" id="headerlogo">
                  <img src="assets/perazzi/logo-perazzi.png" class="img-fluid" alt="Perazzi">
                </a>
              </div>
            </div>
            <div class="menu-left pull-left">
              <div>
                <app-menu-top (changeTab)="changeMenuPage()"></app-menu-top>
              </div>
              <div>
                <!--icons-->
                <app-settings></app-settings>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>